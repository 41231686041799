import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { postTimesheetData } from "common/api/ApiService";
import moment from "moment-business-days";
import "./index.scss";
import { Typography } from "@material-ui/core";
import { capitalize } from "utils/Capitalize";
import { useLocation } from "react-router-dom";
import { parse } from "path";

interface ProjectData {
  name: string;
  issues: {
    name: string;
    description: string | null;
    issueId: number;
    assignee: {
      firstName: string | null;
    };
    timeLogs: {
      loggedTime: number;
      comments: string | null;
      jiraTimeLoggedAt: string;
      jiraTimeLoggedByName?: string;
    }[];
  }[];
}

interface WorkItem {
  name: string;
  time: number;
  date: string;
}

interface AssigneeData {
  name: string;
  issues: {
    monthYear: string;
    workItems: WorkItem[];
  }[];
}

interface ProjectSummary {
  project: string;
  assignees: AssigneeData[];
  totalHours: number;
}

interface Payload {
  projectId: number[];
  fromDate: string;
  toDate: string;
}

interface SubtimeSheetProps {
  selectedProjectData: ProjectData[];
  setSelectedProjectData: React.Dispatch<React.SetStateAction<ProjectData[]>>;
}
const NewTabTimesheets: React.FC<SubtimeSheetProps> = ({
  selectedProjectData,
  setSelectedProjectData,
}) => {
  const [filteredData, setFilteredData] = useState<ProjectSummary[]>([]);
  // Fetch data when the component mounts or the payload changes
  useEffect(() => {
    const storedData = localStorage.getItem("selectedProjectData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setSelectedProjectData(parsedData);
      const filteredProjects = filterProjectData(parsedData);
      setFilteredData(filteredProjects);
    }
  }, []);
  const filterProjectData = (
    selectedProjectData: ProjectData[]
  ): ProjectSummary[] => {
    //  console.log (
    //     "selectedProjectData",
    //     JSON.stringify(selectedProjectData, null, 2)
    //   );
    return selectedProjectData
      ?.map((project) => {
        const assigneesMap = new Map<string, AssigneeData>();
        project.issues.forEach((issue) => {
          issue.timeLogs.forEach((timeLog) => {
            const assigneeName = timeLog.jiraTimeLoggedByName || "User";
            if (assigneeName === "User") return;
            let assigneeData = assigneesMap.get(assigneeName);

            if (!assigneeData) {
              assigneeData = { name: assigneeName, issues: [] };
              assigneesMap.set(assigneeName, assigneeData);
            }
            const createdAt = new Date(timeLog.jiraTimeLoggedAt);
            const monthYear = `${createdAt.toLocaleString("default", {
              month: "short",
            })} ${createdAt.getFullYear()}`;

            let existingMonthYearIssue = assigneeData!.issues.find(
              (i) => i.monthYear === monthYear
            );

            if (!existingMonthYearIssue) {
              existingMonthYearIssue = {
                monthYear,
                workItems: [],
              };
              assigneeData!.issues.push(existingMonthYearIssue);
            }

            const formattedDate = `${createdAt
              .getDate()
              .toString()
              .padStart(2, "0")}-${createdAt.toLocaleString("default", {
              month: "short",
            })}-${createdAt.getFullYear()}`;

            const workItem: WorkItem = {
              name: issue.name,
              time: Number((timeLog.loggedTime / 3600).toFixed(2)),
              date: formattedDate,
            };

            existingMonthYearIssue.workItems.push(workItem);
            existingMonthYearIssue.workItems.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateA.getTime() - dateB.getTime();
            });
          });
        });

        // const assignees = Array.from(assigneesMap.values());
        const assignees = Array.from(assigneesMap.values()).sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        // Calculate subtotal and total hours for each assignee
        assignees.forEach((assignee) => {
          if (assignee.name === "User") return; // Skip subtotal calculation for "User" assignee
          // Calculate subtotal for each assignee based on month used previously may be required for further-Madhuri
          // const months = new Map<string, number>();
          // assignee.issues.forEach((issue) => {
          //   const monthYear = issue.monthYear;
          //   const totalHours = issue.workItems.reduce(
          //     (total, workItem) =>
          //       total + (workItem.name !== "Subtotal" ? workItem.time : 0),
          //     0
          //   );
          //   months.set(monthYear, (months.get(monthYear) || 0) + totalHours);
          // });

          // months.forEach((totalHours, monthYear) => {
          //   const existingMonthIssueIndex = assignee.issues.findIndex(
          //     (issue) => issue.monthYear === monthYear
          //   );
          //   if (
          //     existingMonthIssueIndex !== -1 &&
          //     monthYear !== "Total" &&
          //     monthYear !== "Subtotal"
          //   ) {
          //     const subtotalWorkItem: WorkItem = {
          //       name: "Subtotal",
          //       time: totalHours,
          //       date: "",
          //     };
          //     assignee.issues[existingMonthIssueIndex].workItems.push(
          //       subtotalWorkItem
          //     );
          //   }
          // });

          const assigneeTotalHours = assignee.issues
            .filter((issue) => issue.monthYear !== "Total")
            .reduce((total, issue) => {
              return (
                total +
                issue.workItems.reduce((workItemTotal, workItem) => {
                  return (
                    workItemTotal +
                    (workItem.name !== "Subtotal" ? workItem.time : 0)
                  );
                }, 0)
              );
            }, 0);

          assignee.issues.push({
            monthYear: "Total",
            workItems: [
              {
                name: "Total",
                time: assigneeTotalHours,
                date: "",
              },
            ],
          });
        });

        // Remove assignees with both total and subtotal times equal to zero
        const filteredAssignees = assignees.filter((assignee) =>
          assignee.issues.some(
            (issue) =>
              issue.monthYear !== "Total" &&
              issue.workItems.some((item) => item.time !== 0)
          )
        );

        // Calculate total hours for the project
        const projectTotalHours = filteredAssignees.reduce(
          (total, assignee) => {
            const assigneeTotalHours = assignee.issues
              .filter((issue) => issue.monthYear !== "Total")
              .reduce((assigneeTotal, issue) => {
                return (
                  assigneeTotal +
                  issue.workItems.reduce((workItemTotal, workItem) => {
                    return (
                      workItemTotal +
                      (workItem.name !== "Subtotal" ? workItem.time : 0)
                    );
                  }, 0)
                );
              }, 0);
            return total + assigneeTotalHours;
          },
          0
        );

        // Calculate the number of working days for the project
        const allDates = project.issues.flatMap((issue) =>
          issue.timeLogs.map((log) => new Date(log.jiraTimeLoggedAt))
        );
        const earliestDate = allDates.reduce((earliest, current) =>
          current < earliest ? current : earliest
        );

        return {
          project: project.name,
          assignees: filteredAssignees,
          totalHours: projectTotalHours,
        };
      })
      .sort((a, b) => {
        const nameA = a.project.toUpperCase();
        const nameB = b.project.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .filter((project) => project.totalHours !== 0);
  };

  return (
    <TableContainer
      component={Paper}
      className="project-table-container-subtimesheets"
    >
      {filteredData.length === 0 ? (
        <Typography
          variant="h6"
          align="center"
          className="empty-records-subtimesheets"
        >
          No records found
        </Typography>
      ) : (
        <Table className="project-table-subtimesheets">
          <TableHead>
            <TableRow>
              <TableCell
                className="table-header-subtimesheets"
                style={{ fontWeight: "bold" }}
              >
                Project
              </TableCell>
              <TableCell
                className="table-header-subtimesheets"
                style={{ fontWeight: "bold", width: "200px" }}
              >
                Assignee
              </TableCell>
              <TableCell
                className="table-header-subtimesheets"
                style={{ fontWeight: "bold" }}
              >
                Month/Year
              </TableCell>
              <TableCell
                className="table-header-subtimesheets"
                style={{ fontWeight: "bold", width: "600px" }}
              >
                Work Item
              </TableCell>
              <TableCell
                className="table-header-subtimesheets"
                style={{ fontWeight: "bold" }}
              >
                Date
              </TableCell>
              <TableCell
                className="table-header-subtimesheets"
                style={{ fontWeight: "bold" }}
              >
                Time Logged (hrs)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((project, projectIndex) => (
              <React.Fragment key={`project_${projectIndex}`}>
                {project.assignees.map((assignee, assigneeIndex) =>
                  assignee.issues.map((issue, issueIndex) =>
                    issue.workItems.map((workItem, workIndex) => (
                      <TableRow
                        key={`${project.project}_${assignee.name}_${issue.monthYear}_${workItem.name}_${workIndex}`}
                      >
                        {workIndex === 0 &&
                          assigneeIndex === 0 &&
                          issueIndex === 0 && (
                            <TableCell
                              rowSpan={project.assignees.reduce(
                                (acc, currAssignee) =>
                                  acc +
                                  currAssignee.issues.reduce(
                                    (issueAcc, currIssue) =>
                                      issueAcc + currIssue.workItems.length,
                                    0
                                  ),
                                0
                              )}
                              className="table-cell-subtimesheets"
                            >
                              {project.project && capitalize(project.project)}
                            </TableCell>
                          )}
                        {workIndex === 0 && issueIndex === 0 && (
                          <TableCell
                            rowSpan={assignee.issues.reduce(
                              (acc, currIssue) =>
                                acc + currIssue.workItems.length,
                              0
                            )}
                            className="table-cell-subtimesheets"
                          >
                            {assignee.name && capitalize(assignee.name)}
                          </TableCell>
                        )}
                        {workIndex === 0 && issue.monthYear !== "Total" && (
                          <TableCell
                            rowSpan={issue.workItems.length}
                            className="table-cell-subtimesheets"
                          >
                            {issue.monthYear !== "NaN" ? issue.monthYear : ""}
                          </TableCell>
                        )}
                        {workIndex === 0 && issue.monthYear === "Total" && (
                          <TableCell
                            colSpan={3}
                            className="table-cell-subtimesheets"
                            style={{
                              fontWeight: 600,
                              // textAlign:"right",
                              paddingRight: "20px",
                            }}
                          >
                            <b>{`${
                              assignee.name && capitalize(assignee.name)
                            } Sub Total`}</b>
                          </TableCell>
                        )}
                        {issue.monthYear !== "Total" && (
                          <TableCell
                            className="table-cell-subtimesheets"
                            style={{
                              fontWeight:
                                workItem.name === "Total" ? 600 : "normal",
                              paddingRight: "20px",
                              width: workItem.name === "Total" ? "" : "500px",
                            }}
                            colSpan={workItem.name === "Subtotal" ? 2 : 1}
                          >
                            {workItem.name !== "Subtotal" ? (
                              workItem.name && capitalize(workItem.name)
                            ) : (
                              <b>{issue.monthYear} Sub Total </b>
                            )}
                          </TableCell>
                        )}
                        {issue.monthYear !== "Total" &&
                          workItem.name !== "Subtotal" && (
                            <TableCell className="table-cell-subtimesheets">
                              {workItem.date !== "NaN-Invalid Date-NaN"
                                ? workItem.date
                                : ""}
                            </TableCell>
                          )}
                        {(issue.monthYear === "Total" ||
                          workItem.name === "Subtotal") && (
                          <TableCell
                            className="table-cell-subtimesheets"
                            style={{ textAlign: "right", paddingRight: "10px" }}
                          >
                            {!isNaN(workItem.time) ? (
                              <b>{workItem.time} hrs</b>
                            ) : (
                              0
                            )}
                          </TableCell>
                        )}
                        {issue.monthYear !== "Total" &&
                          workItem.name !== "Subtotal" && (
                            <TableCell
                              className="table-cell-subtimesheets"
                              style={{
                                textAlign: "right",
                                paddingRight: "10px",
                              }}
                            >
                              {!isNaN(workItem.time) ? workItem.time : 0}
                            </TableCell>
                          )}
                      </TableRow>
                    ))
                  )
                )}
                <TableRow>
                  {/*
                    It will be used further to display no of business working days -Madhuri
                    */}
                  {/* <TableCell
                    className="table-cell-subtimesheets"
                    colSpan={4}
                    style={{ fontWeight: "bold" }}
                  >{` ${project.project} Sub Total`}</TableCell>
                  <TableCell
                    className="table-cell-subtimesheets"
                    style={{ fontWeight: "bold" }}
                  >{`Working Days: ${project.workingDays}`}</TableCell> */}
                  <TableCell
                    className="table-cell-subtimesheets"
                    colSpan={5}
                    style={{ fontWeight: "bold" }}
                  >{` ${
                    project.project && capitalize(project.project)
                  } Sub Total`}</TableCell>
                  <TableCell
                    className="table-cell-subtimesheets"
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                  >{` ${project.totalHours.toFixed(2)} hrs`}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default NewTabTimesheets;
