import React from 'react';
 // Replace with your CSS file path
import './index.scss'
const Satisfaction = () => {
  return (
    <div>
     

          <span className="circle darkgreen"></span> A+ : Excellent
       <pre><span className="circle green"></span> A   : Very Good</pre>
          <pre><span className="circle yellow"></span> B   : Moderate</pre>
          
          <pre><span className="circle orange"></span> C   : Low</pre> 
       <pre><span className="circle red"></span> D  : Very Low</pre>  
    </div>
  );
};

export default Satisfaction;
