import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import { getSyncRequestById } from "common/api/ApiService";
import { IoArrowBack, IoArrowDown, IoArrowUp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "components/DashboardHeader";
import "./index.scss";
import { trimAndTooltip } from "utils/TrimandToolTip";
import { convertToIST } from "utils/ConvertToIST";
import { SyncRequest } from "models/ISyncReport";

const SyncRequestDetailedReport: React.FC = () => {
  const [syncRequestData, setSyncRequestData] = React.useState<SyncRequest[]>(
    []
  );
  const { requestId } = useParams<{ requestId: string }>();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [syncType, setSyncType] = React.useState<string | null>(null);
  const [assigneeName, setAssigneeName] = React.useState<string | null>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollDirection] = useState<"down" | "up">("down");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSyncRequestData = async () => {
      if (!requestId) {
        return;
      }
      setLoading(true);
      try {
        const numericRequestId = parseInt(requestId, 10);
        const resp = await getSyncRequestById(numericRequestId);
        if (resp.length) {
          setSyncType(resp[0].syncType);
          setAssigneeName(resp[0].assigneeName);
        }
        setSyncRequestData(resp);
      } catch (error) {
        console.error("Error fetching sync request data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSyncRequestData();
  }, [requestId]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSyncRequestTypeName = (syncType: number): string => {
    switch (syncType) {
      case 1:
        return "Scheduler";
      case 2:
        return "Manual";
      case 3:
        return "Webhook";
      default:
        return "Unknown";
    }
  };

  const getSyncStatusTypeName = (status: number): string => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "In Progress";
      case 3:
        return "Success";
      case 4:
        return "Failure";
      case 5:
        return "Cancelled";
      default:
        return "Unknown";
    }
  };

  const getStatusColor = (status: number): string => {
    switch (status) {
      case 1:
        return "gray";
      case 2:
        return "blue";
      case 3:
        return "green";
      case 4:
        return "red";
      case 5:
        return "orange";
      default:
        return "black";
    }
  };

  const handleScrollButtonClick = () => {
    if (tableContainerRef.current) {
      if (scrollDirection === "down") {
        tableContainerRef.current.scrollTop =
          tableContainerRef.current.scrollHeight;
        setScrollDirection("up");
      } else {
        tableContainerRef.current.scrollTop = 0;
        setScrollDirection("down");
      }
    }
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <DashboardHeader />
      <TableContainer
        component={Paper}
        className="sync-report-table-container"
        ref={tableContainerRef}
      >
        <div style={{ padding: "20px" }}>
          <Typography variant="h6" gutterBottom>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                onClick={() => navigate("/project-iq/sync-report")}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "24px",
                }}
                aria-label="Go back"
                role="button"
              >
                <IoArrowBack />
              </span>
              <div
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                }}
              >
                <h3
                  style={{
                    margin: 0,
                    marginLeft: "20px",
                    fontSize: "24px",
                    display: "inline-block",
                    textAlign: "center",
                    width: "100%",
                    lineHeight: 0,
                  }}
                >
                  Sync Detailed Report
                </h3>
              </div>
              <div
                style={{
                  textAlign: "left",
                  flexShrink: 0,
                  fontWeight: "bold",
                }}
              >
                {syncType && parseInt(syncType) === 2 && (
                  <div style={{ fontSize: "16px" }}>By: {assigneeName}</div>
                )}
                <div style={{ fontSize: "16px" }}>
                  Sync Type: {getSyncRequestTypeName(parseInt(syncType ?? "0"))}
                </div>
              </div>
            </div>
          </Typography>
        </div>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Serial No
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Project Name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Sync Status
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Message
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Started
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Completed
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {syncRequestData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  style={{ borderBottom: "1px solid #ddd" }}
                >
                  <TableCell style={{ border: "1px solid #ddd" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd" }}>
                    {trimAndTooltip(row.projectName || "", 20)}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #ddd",
                      color: getStatusColor(parseInt(row.status)),
                    }}
                  >
                    {getSyncStatusTypeName(parseInt(row.status))}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd" }}>
                    {trimAndTooltip(row.message, 25)}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd" }}>
                    {row.startedAt ? convertToIST(row.startedAt) : "--"}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd" }}>
                    {row.completedAt ? convertToIST(row.completedAt) : "--"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <button className="scroll-button" onClick={handleScrollButtonClick}>
          {scrollDirection === "down" ? <IoArrowDown /> : <IoArrowUp />}
        </button>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, syncRequestData.length]}
          component="div"
          count={syncRequestData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
};

export default SyncRequestDetailedReport;
