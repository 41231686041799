import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './index.scss';

interface DateRangePickerProps {
  selectedDates: { startDate: string | null; endDate: string | null };
  setSelectedDates: React.Dispatch<
    React.SetStateAction<{
      startDate: string | null;
      endDate: string | null;
    }>
  >;
  isDisabled?: boolean;
}

// DateRangePicker component for selecting a start and end date with validation.
function DateRangePicker({ selectedDates, setSelectedDates, isDisabled }: DateRangePickerProps) {
  const [alert, setAlert] = useState<{ show: boolean; message: string }>({ show: false, message: '' });

  // Handles changes to the start date input field, ensuring it is less than the end date; displays alert if not.
  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = event.target.value;
    if (selectedDates.endDate && startDate > selectedDates.endDate) {
      showAlert("Start date must be Less than the end date!");
      return;
    }
  
    setSelectedDates((prevDates) => ({
      ...prevDates,
      startDate: event.target.value,
    }));
  };

  // Handles changes to the end date input field, ensuring it is greater than the start date; displays alert if not.
  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const endDate = event.target.value;
    if (selectedDates.startDate && endDate < selectedDates.startDate) {
      showAlert("End date must be greater than the start date!");
      return;
    }
  
    setSelectedDates((prevDates) => ({
      ...prevDates,
      endDate: endDate,
    }));
  };

  // Displays an alert message for a specified duration and then hides it.
  const showAlert = (message: string) => {
    setAlert({ show: true, message });
    setTimeout(() => {
      setAlert({ show: false, message: '' });
    }, 3000);
  };

  // Closes the alert message.
  const handleCloseAlert = () => {
    setAlert({ show: false, message: '' });
  };

  return (
    <div>
      {alert.show && (
        <Alert
          severity="warning"
          className="floating-alert"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {alert.message}
        </Alert>
      )}
      <div className='date__calendar'>
      <div className='date-input-calendar'>
      <label htmlFor="start-date">From:</label>
      <input
        type="date"
        id="start-date"
        value={selectedDates.startDate || ''}
        onChange={handleStartDateChange}
        className={`date-input ${isDisabled ? 'disabled' : ''}`}
        disabled={isDisabled}
      />
      </div>
      <div className='date-input-calendar'>
      <label htmlFor="end-date">To:</label>
      <input
        type="date"
        id="end-date"
        value={selectedDates.endDate || ''}
        onChange={handleEndDateChange}
        className={`date-input ${isDisabled ? 'disabled' : ''}`}
        disabled={isDisabled}
      />
      </div>
      </div>
    </div>
  );
}

export default DateRangePicker;
