import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Container,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Autocomplete,
  IconButton,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import "./index.scss";
import {
  Assignee,
  Project,
  Resource,
  createResource,
  getAllAssignees,
  getAllProjects,
  getResourceById,
  updateResource,
} from "common/api/ApiService";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./index.scss";

export interface ResourceDataDashboard {
  firstName: string;
  email: string;
  departmentId: number;
  employementType: string;
  freeBy: Date | null;
  freeFrom: Date | null;
  freeTo: Date | null;
  newProjects: { projectName: string }[];
  projects: { projectId: number }[];
  availabilityStatusId: number;
  inHouse: number;
  hasJiraAccess: number;
}

export const EmployeementTypeOptions = [
  { value: "", label: "Select Employee Type", disabled: true },
  { value: "full-time", label: "Full Time" },
  { value: "intern", label: "Intern" },
];

export const AvailabilityStatusOptions = [
  { value: 0, label: "Select Availability Status", disabled: true },
  { value: 1, label: "Engaged" },
  { value: 2, label: "Training" },
  { value: 3, label: "Free in few days" },
  { value: 4, label: "Bench" },
  { value: 5, label: "On Leave" },
  { value: 6, label: "Free" },
  { value: 7, label: "Leaving Soon" },
  { value: 8, label: "Need Replacement" },
  { value: 9, label: "Assigned" },
  { value: 10, label: "Yet to Onboard" },
  { value: 11, label: "Partially Free" },
  { value: 12, label: "Internal Project" },
  { value: 13, label: "COE" },
  { value: 14, label: "Shadowing" },
];

export const departmentIdOptions = [
  { value: 0, label: "Select departmentId ID", disabled: true },
  { value: 1, label: "Design" },
  { value: 2, label: "Development" },
  { value: 3, label: "QA" },
  { value: 4, label: "PO" },
  { value: 5, label: "PM" },
  { value: 6, label: "DevOps" },
  { value: 7, label: "DM" },
  { value: 8, label: "Accountant" },
  { value: 9, label: "HR" },
  { value: 10, label: "Sales" },
  { value: 11, label: "Marketing" },
  { value: 12, label: "Finance" },
];

interface CreateFormProps {
  selectedResourceId: string;
  setSelectedResourceId: (selectedResourceId: string) => void;
  isResourceEdit: boolean;
  setIsResourceEdit: (isResourceEdit: boolean) => void;
  isResourceSuccessEdit: boolean;
  setIsResourceSuccessEdit: (isResourceSuccessEdit: boolean) => void;
}
const initialResourceFormState: ResourceDataDashboard = {
  firstName: "",
  email: "",
  departmentId: 0,
  employementType: "",
  availabilityStatusId: 0,
  freeBy: null,
  freeFrom: null,
  freeTo: null,
  inHouse: 0,
  hasJiraAccess: 0,
  newProjects: [],
  projects: [],
};

const CreateResourceForm: React.FC<CreateFormProps> = ({
  selectedResourceId,
  setSelectedResourceId,
  isResourceEdit,
  setIsResourceEdit,
  isResourceSuccessEdit,
  setIsResourceSuccessEdit,
}) => {
  const containerRef = useRef(null);
  const [formResourceData, setFormResourceData] =
    useState<ResourceDataDashboard>(initialResourceFormState);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDateError, setShowDateError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [assigneeData, setAssigneeData] = useState<Assignee[]>([]);
  const [selectedSubProject, setSelectedSubProject] = useState<Project[]>([]);
  const [dropdownProjects, setDropdownProjects] = useState<Project[]>([]);
  const [assigneeExist, setAssigneeExist] = useState<boolean>(false);
  const [selectedResourceData, setSelectedResourceData] = useState<Resource[]>(
    []
  );
  const [fetchedProjects, setFetchecProjects] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectList = async () => {
      try {
        const data = await getAllProjects(2, [1, 2, 4], 3);
        setDropdownProjects(data);
        setFetchecProjects(true);
      } catch (error) {
        showAlertMessage("error", "Error while fetching project data");
        console.error("Error while fetching project data", error);
      }
    };
    fetchProjectList();
  }, []);

  useEffect(() => {
    const fetchAssigneeData = async () => {
      try {
        const data = await getAllAssignees();
        setAssigneeData(data);
      } catch (error) {
        showAlertMessage("error", "Error while fetching assignee data");
        console.error("Error while fetching assignee data", error);
      }
    };
    fetchAssigneeData();
  }, []);

  useEffect(() => {
    if (
      selectedResourceId !== "0" &&
      isResourceEdit &&
      selectedResourceId !== ""
    ) {
      const fetchAssigneeById = async () => {
        setLoading(true);
        try {
          const resp = await getResourceById(parseInt(selectedResourceId));
          if (resp) {
            showAlertMessage("success", "Fetched Resource Successfully");
            setSelectedResourceData(resp);
            const selectedResource = resp[0];
            // console.log(selectedResource);
            if (selectedResource) {
              setFormResourceData({
                ...initialResourceFormState,
                firstName:
                  selectedResource.firstName ||
                  initialResourceFormState.firstName,
                email: selectedResource.email || initialResourceFormState.email,
                departmentId:
                  Number(selectedResource.departmentId) ||
                  initialResourceFormState.departmentId,
                availabilityStatusId:
                  Number(selectedResource.availabilityStatusId) ||
                  initialResourceFormState.availabilityStatusId,
                employementType:
                  selectedResource.employementType ||
                  initialResourceFormState.employementType,
                freeBy: selectedResource.freeBy
                  ? new Date(selectedResource.freeBy) ||
                    initialResourceFormState.freeBy
                  : initialResourceFormState.freeBy,
                freeFrom: selectedResource.freeFrom
                  ? new Date(selectedResource.freeFrom) ||
                    initialResourceFormState.freeFrom
                  : initialResourceFormState.freeFrom,
                freeTo: selectedResource.freeTo
                  ? new Date(selectedResource.freeTo) ||
                    initialResourceFormState.freeTo
                  : initialResourceFormState.freeTo,
                inHouse:
                  Number(selectedResource.inHouse) ||
                  initialResourceFormState.inHouse,
                hasJiraAccess:
                  Number(selectedResource.hasJiraAccess) ||
                  initialResourceFormState.hasJiraAccess,
                projects:
                  selectedResource.projects ||
                  initialResourceFormState.projects,
              });
            }
            if (selectedResource.projects !== null) {
              // console.log("htret");
              const mappedProjects = selectedResource.projects
                .map((resproject) =>
                  dropdownProjects.find(
                    (project) =>
                      parseInt(project.project_id) === resproject.projectId
                  )
                )
                .filter((project) => project !== undefined) as Project[];
              // console.log(mappedProjects);
              // console.log(dropdownProjects);
              setSelectedSubProject(mappedProjects);
            }

            // console.log(selectedSubProject);
            // console.log(formResourceData);
          } else {
            showAlertMessage("error", "Error while fetching Resource");
          }
        } catch (error) {
          console.error("Error while fetching all resources", error);
        } finally {
          setLoading(false);
        }
      };
      fetchAssigneeById();
    }
  }, [fetchedProjects]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (showDateError) {
      timeoutId = setTimeout(() => {
        setShowDateError(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showDateError]);

  const showAlertMessage = (type: "success" | "error", message: string) => {
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 2000);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let updatedValue:
      | string
      | number
      | Date
      | string[]
      | number[]
      | { projectName: string }[] = value;
    // console.log(value);
    if (name === "email") {
      const assigneeExists = assigneeData.some(
        (assignee) => assignee.assignee_email === value
      );
      setAssigneeExist(assigneeExists);
      if (assigneeExists) {
        setShowConfirmDialog(true);
      }
    }
    if (name === "newProjects") {
      const stringArray = value
        .split(",")
        .map((item) => ({ projectName: item.trim() }));
      updatedValue = stringArray;
    }

    if (name === "inHouse" || name === "hasJiraAccess") {
      updatedValue = e.target.checked ? 1 : 0;
    }
    if (name === "employementType") {
      updatedValue = value;
    }
    // console.log(updatedValue);
    const updatedFormData = {
      ...formResourceData,
      [name]: updatedValue,
    };
    setFormResourceData(updatedFormData);
  };

  const handleSubmit = async () => {
    const requiredFields = [
      { field: formResourceData.firstName, message: "First Name" },
      { field: formResourceData.email, message: "Email" },
      { field: formResourceData.departmentId, message: "Department" },
      {
        field: formResourceData.availabilityStatusId,
        message: "Availability Status",
      },
      { field: formResourceData.employementType, message: "Employment Type" },
    ];

    const missingFields = requiredFields
      .filter((field) => !field.field)
      .map((field) => field.message);

    if (missingFields.length >= 5) {
      showAlertMessage("error", "Please fill in all required fields");
      return;
    } else if (missingFields.length > 0) {
      showAlertMessage(
        "error",
        `Please fill in the following required fields: ${missingFields.join(
          ", "
        )}`
      );
      return;
    }
    if (assigneeExist) {
      showAlertMessage("error", "Same Email Address Already Exists Check Once");
      return;
    }
    if (isResourceEdit) {
      try {
        const resp = await updateResource(
          parseInt(selectedResourceId),
          formResourceData
        );
        // console.log(formResourceData);
        if (resp) {
          showAlertMessage("success", "Updated Resource Successfully");
          setTimeout(() => {
            handleReset();
            navigate("/resource-dashboard");
          }, 2000);
        } else {
          showAlertMessage("error", "Error while Updating Resource");
        }
      } catch (error) {
        throw new Error("Error at Updating resource");
      }
    } else {
      try {
        // console.log(formResourceData);
        const resp = await createResource(formResourceData);
        // console.log(formResourceData);
        if (resp) {
          showAlertMessage("success", "Created Resource Successfully");
          setTimeout(() => {
            handleReset();
            navigate("/resource-dashboard");
          }, 2000);
        } else {
          showAlertMessage("error", "Error while Creating Resource");
        }
      } catch (error) {
        throw new Error("Error at creating resource");
      }
    }
  };

  const handleReset = () => {
    setFormResourceData({
      ...initialResourceFormState,
      freeBy: null,
      freeFrom: null,
      freeTo: null,
    });
    setSelectedSubProject([]);
  };

  const handleContinue = () => {
    setShowConfirmDialog(false);
    // console.log(formData);

    setTimeout(() => {
      handleReset();
      navigate("/resource-dashboard");
    }, 1000);
  };

  const handleCloseForm = () => {
    setFormResourceData(initialResourceFormState);
    navigate("/resource-dashboard");
  };

  return (
    <Container maxWidth="md" className="form-container">
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {showAlert && (
        <Alert
          severity={alertType}
          className="floating-alert"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setShowAlert(false)}
            >
              <IoCloseCircleOutline fontSize="inherit" />
            </IconButton>
          }
        >
          {alertMessage}
        </Alert>
      )}
      <Container maxWidth="md" className="form-container-resource">
        <Paper className="paper-container-resource">
          <div className="paper-header">
            <h1 className="form-heading">
              {isResourceEdit ? "Update Resource" : "Create Resource"}
            </h1>
            <span className="close-icon" onClick={handleCloseForm}>
              <IoCloseCircleOutline />
            </span>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                required
                variant="outlined"
                placeholder="Resource Name"
                fullWidth
                value={formResourceData.firstName}
                onChange={handleChange}
                name="firstName"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color:
                      formResourceData.firstName === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Address"
                required
                variant="outlined"
                placeholder="Resource Email Address"
                fullWidth
                value={formResourceData.email}
                onChange={handleChange}
                name="email"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color: formResourceData.email === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
              <Autocomplete
                multiple
                options={dropdownProjects}
                getOptionLabel={(option) => option.name}
                value={selectedSubProject}
                onChange={(event, newValue) => {
                  setSelectedSubProject(newValue as Project[]);
                  const selectedIds = newValue.map((project) => ({
                    projectId: parseInt(project.project_id),
                  }));
                  setFormResourceData({
                    ...formResourceData,
                    projects: selectedIds,
                  });
                }}
                renderTags={(tagValue, getTagProps) => {
                  if (containerRef.current) {
                    (containerRef.current as HTMLDivElement).scrollTop = (
                      containerRef.current as HTMLDivElement
                    ).scrollHeight;
                  }

                  return (
                    <div
                      ref={containerRef}
                      style={{
                        maxHeight: "2.5em",
                        overflowY: "auto",
                        maxWidth: "100%",
                      }}
                    >
                      {tagValue.map((option, index) => (
                        <Chip
                          label={option.name}
                          {...getTagProps({ index })}
                          key={`${option.project_id}-${index}`}
                        />
                      ))}
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Primary Projects"
                    placeholder="Select Projects from Existing projects"
                    fullWidth
                    margin="normal"
                    // multiline={true}
                    rows={3}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="New Primary Projects"
                variant="outlined"
                placeholder="New Primary Projects"
                fullWidth
                value={
                  formResourceData.newProjects
                    ? formResourceData.newProjects
                        .map(
                          (project: { projectName: string }) =>
                            project.projectName
                        )
                        .join(", ")
                    : ""
                }
                onChange={handleChange}
                name="newProjects"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color:
                      formResourceData?.newProjects?.length === 0
                        ? "gray"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Availability Status"
                variant="outlined"
                required
                fullWidth
                value={formResourceData.availabilityStatusId}
                onChange={handleChange}
                name="availabilityStatusId"
                SelectProps={{
                  MenuProps: {
                    classes: { paper: "custom-dropdown-menu" },
                    style: { maxHeight: 300 },
                  },
                }}
                InputProps={{
                  style: {
                    color:
                      formResourceData.availabilityStatusId === 0
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              >
                {AvailabilityStatusOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Employee Type"
                variant="outlined"
                required
                fullWidth
                value={formResourceData.employementType}
                onChange={handleChange}
                name="employementType"
                InputProps={{
                  style: {
                    color:
                      formResourceData.employementType === ""
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
                InputLabelProps={{ shrink: true }}
              >
                {EmployeementTypeOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Free By"
                variant="outlined"
                fullWidth
                type="date"
                value={
                  formResourceData.freeBy instanceof Date
                    ? formResourceData.freeBy?.toISOString().split("T")[0]
                    : initialResourceFormState.freeBy
                }
                onChange={handleChange}
                name="freeBy"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color:
                      formResourceData.freeBy === null
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                label="Free From"
                variant="outlined"
                fullWidth
                type="date"
                value={
                  formResourceData.freeFrom instanceof Date
                    ? formResourceData.freeFrom?.toISOString().split("T")[0]
                    : initialResourceFormState.freeFrom
                }
                onChange={handleChange}
                name="freeFrom"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color:
                      formResourceData.freeFrom === null
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Free To"
                variant="outlined"
                fullWidth
                type="date"
                value={
                  formResourceData.freeTo instanceof Date
                    ? formResourceData.freeTo?.toISOString().split("T")[0]
                    : initialResourceFormState.freeTo
                }
                onChange={handleChange}
                name="freeTo"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color:
                      formResourceData.freeTo === null
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="department"
                required
                variant="outlined"
                fullWidth
                value={formResourceData.departmentId}
                onChange={handleChange}
                name="departmentId"
                InputProps={{
                  style: {
                    color:
                      formResourceData.departmentId === 0
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              >
                {departmentIdOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formResourceData.inHouse === 1}
                    onChange={handleChange}
                    name="inHouse"
                  />
                }
                label="In House"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formResourceData.hasJiraAccess === 1}
                    onChange={handleChange}
                    name="hasJiraAccess"
                  />
                }
                label="Jira Access"
              />
            </Grid> */}
          </Grid>
          <div className="resource-form-buttons">
            <Button variant="contained" color="primary" onClick={handleReset}>
              Clear
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Paper>
      </Container>
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Resource Already Exists
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Resource Already Exists Go to Dashboard and Edit...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)} color="primary">
            Back
          </Button>
          <Button onClick={handleContinue} color="primary" autoFocus>
            Go to Dashboard
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CreateResourceForm;
