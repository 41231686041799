import React, { useEffect, useState } from "react";
import { Button, Tooltip, Badge } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useAccess } from "../../AccessContext";
import "./index.scss";
import DashboardHeader from "components/DashboardHeader";
import { validateResourceDashboard } from "common/api/ApiService";
import styled from "@emotion/styled";

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const { hasAccess, isLogged } = useAccess();
  const [isResourceDashboardValid, setIsResourceDashboardValid] =
    useState(false);

  useEffect(() => {
    const validateResourceDashboardData = async () => {
      try {
        const response = await validateResourceDashboard();
        setIsResourceDashboardValid(response.length === 0);
      } catch (error) {
        console.error(error);
      }
    };
    validateResourceDashboardData();
  }, []);

  if (isLogged && !hasAccess) {
    return <Navigate to="/project-iq" />;
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-dot": {
      minWidth: 13,
      minHeight: 13,
      borderRadius: "50%",
    },
  }));
  return (
    <div>
      <DashboardHeader />
      <div className="homescreen_div">
        <Button
          className="button_homescreen"
          onClick={() => navigate("/project-iq")}
          disabled={!isLogged}
        >
          Timesheets
        </Button>
        {hasAccess && (
          <>
            <Button
              className="button_homescreen"
              onClick={() => {
                navigate("/summary-dashboard");
              }}
              disabled={!isLogged}
            >
              Opportunity Dashboard
            </Button>
            <Button
              className="button_homescreen"
              onClick={() => {
                navigate("/project-iq/dashboard");
              }}
              disabled={!isLogged}
            >
              Internal Dashboard
            </Button>
            <Tooltip
              title="Please fill all the data in the resource dashboard"
              arrow
              disableHoverListener={isResourceDashboardValid}
            >
              <StyledBadge
                color="error"
                variant="dot"
                invisible={isResourceDashboardValid}
              >
                <Button
                  className="button_homescreen"
                  onClick={() => {
                    navigate("resource-dashboard");
                  }}
                  style={
                    isResourceDashboardValid
                      ? { color: "bormla" }
                      : { color: "red" }
                  }
                  disabled={!isLogged}
                >
                  People Dashboard
                </Button>
              </StyledBadge>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default HomeScreen;
