import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { postTimesheetData } from "common/api/ApiService";
import moment from "moment-business-days";
import "./index.scss";
import { Typography } from "@material-ui/core";
import { capitalize } from "utils/Capitalize";

export interface ProjectData {
  name: string;
  jiraReference?: string;
  issues: {
    name: string;
    description: string | null;
    issueId: number;
    issueKey?: string;
    assignee: {
      firstName: string | null;
    };
    timeLogs: {
      loggedTime: number;
      comments: string | null;
      jiraTimeLoggedAt: string;
      jiraTimeLoggedByName?: string;
    }[];
  }[];
}

interface WorkItem {
  name: string;
  time: number;
  issueKey: string;
  date: string;
}

interface AssigneeData {
  name: string;
  issues: {
    monthYear: string;
    workItems: WorkItem[];
  }[];
}

interface ProjectSummary {
  project: string;
  jiraReference?: string;
  assignees: AssigneeData[];
  totalHours: number;
  workingDays: number;
}

interface Payload {
  projectId: number[];
  fromDate: string;
  toDate: string;
}

interface ProjectTableProps {
  payload: Payload;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectTable: React.FC<ProjectTableProps> = ({
  payload,
  loading,
  setLoading,
}) => {
  const [filteredData, setFilteredData] = useState<ProjectSummary[]>([]);

  // Fetch data when the component mounts or the payload changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const projects: ProjectData[] = await postTimesheetData(payload);
        // console.log("projects", projects);
        const data = filterProjectData(projects);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching projects data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [payload]);

  const filterProjectData = (projects: ProjectData[]): ProjectSummary[] => {
    return projects
      .map((project) => {
        const assigneesMap = new Map<string, AssigneeData>();
        project.issues.forEach((issue) => {
          issue.timeLogs.forEach((timeLog) => {
            const assigneeName = timeLog.jiraTimeLoggedByName || "User";
            if (assigneeName === "User") return;
            let assigneeData = assigneesMap.get(assigneeName);

            if (!assigneeData) {
              assigneeData = { name: assigneeName, issues: [] };
              assigneesMap.set(assigneeName, assigneeData);
            }
            const createdAt = new Date(timeLog.jiraTimeLoggedAt);
            const monthYear = `${createdAt.toLocaleString("default", {
              month: "short",
            })} ${createdAt.getFullYear()}`;

            let existingMonthYearIssue = assigneeData!.issues.find(
              (i) => i.monthYear === monthYear
            );

            if (!existingMonthYearIssue) {
              existingMonthYearIssue = {
                monthYear,
                workItems: [],
              };
              assigneeData!.issues.push(existingMonthYearIssue);
            }

            const formattedDate = `${createdAt
              .getDate()
              .toString()
              .padStart(2, "0")}-${createdAt.toLocaleString("default", {
              month: "short",
            })}-${createdAt.getFullYear()}`;

            const workItem: WorkItem = {
              name: issue.name,
              issueKey: issue.issueKey || "",
              time: Number((timeLog.loggedTime / 3600).toFixed(2)),
              date: formattedDate,
            };

            existingMonthYearIssue.workItems.push(workItem);
            existingMonthYearIssue.workItems.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateA.getTime() - dateB.getTime();
            });
          });
        });

        // const assignees = Array.from(assigneesMap.values());
        const assignees = Array.from(assigneesMap.values()).sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        // Calculate subtotal and total hours for each assignee
        assignees.forEach((assignee) => {
          if (assignee.name === "User") return; // Skip subtotal calculation for "User" assignee
          // Calculate subtotal for each assignee based on month used previously may be required for further-Madhuri
          // const months = new Map<string, number>();
          // assignee.issues.forEach((issue) => {
          //   const monthYear = issue.monthYear;
          //   const totalHours = issue.workItems.reduce(
          //     (total, workItem) =>
          //       total + (workItem.name !== "Subtotal" ? workItem.time : 0),
          //     0
          //   );
          //   months.set(monthYear, (months.get(monthYear) || 0) + totalHours);
          // });

          // months.forEach((totalHours, monthYear) => {
          //   const existingMonthIssueIndex = assignee.issues.findIndex(
          //     (issue) => issue.monthYear === monthYear
          //   );
          //   if (
          //     existingMonthIssueIndex !== -1 &&
          //     monthYear !== "Total" &&
          //     monthYear !== "Subtotal"
          //   ) {
          //     const subtotalWorkItem: WorkItem = {
          //       name: "Subtotal",
          //       time: totalHours,
          //       date: "",
          //     };
          //     assignee.issues[existingMonthIssueIndex].workItems.push(
          //       subtotalWorkItem
          //     );
          //   }
          // });

          const assigneeTotalHours = assignee.issues
            .filter((issue) => issue.monthYear !== "Total")
            .reduce((total, issue) => {
              return (
                total +
                issue.workItems.reduce((workItemTotal, workItem) => {
                  return (
                    workItemTotal +
                    (workItem.name !== "Subtotal" ? workItem.time : 0)
                  );
                }, 0)
              );
            }, 0);

          assignee.issues.push({
            monthYear: "Total",
            workItems: [
              {
                name: "Total",
                issueKey: "",
                time: parseFloat(assigneeTotalHours.toFixed(2)),
                date: "",
              },
            ],
          });
        });

        // Remove assignees with both total and subtotal times equal to zero
        const filteredAssignees = assignees.filter((assignee) =>
          assignee.issues.some(
            (issue) =>
              issue.monthYear !== "Total" &&
              issue.workItems.some((item) => item.time !== 0)
          )
        );

        // Calculate total hours for the project
        const projectTotalHours = filteredAssignees.reduce(
          (total, assignee) => {
            const assigneeTotalHours = assignee.issues
              .filter((issue) => issue.monthYear !== "Total")
              .reduce((assigneeTotal, issue) => {
                return (
                  assigneeTotal +
                  issue.workItems.reduce((workItemTotal, workItem) => {
                    return (
                      workItemTotal +
                      (workItem.name !== "Subtotal" ? workItem.time : 0)
                    );
                  }, 0)
                );
              }, 0);
            return total + assigneeTotalHours;
          },
          0
        );

        // Calculate the number of working days for the project
        const allDates = project.issues.flatMap((issue) =>
          issue.timeLogs.map((log) => new Date(log.jiraTimeLoggedAt))
        );
        const earliestDate = allDates.reduce((earliest, current) =>
          current < earliest ? current : earliest
        );
        const workingDays = moment(earliestDate).businessDiff(
          moment(payload.toDate)
        );

        return {
          project: project.name,
          jiraReference: project.jiraReference,
          assignees: filteredAssignees,
          totalHours: projectTotalHours,
          workingDays,
        };
      })
      .sort((a, b) => {
        const nameA = a.project.toUpperCase();
        const nameB = b.project.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .filter((project) => project.totalHours !== 0);
  };
  console.log("filteredData", JSON.stringify(filteredData, null, 2));
  return (
    <TableContainer component={Paper} className="project-table-container">
      {!loading && filteredData.length === 0 ? (
        <Typography variant="h6" align="center" className="empty-records">
          No records found
        </Typography>
      ) : (
        <Table className="project-table">
          <TableHead>
            <TableRow>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold" }}
              >
                Project
              </TableCell>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold", width: "200px" }}
              >
                Assignee
              </TableCell>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold" }}
              >
                Month/Year
              </TableCell>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold", width: "600px" }}
              >
                Work Item
              </TableCell>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold" }}
              >
                Date
              </TableCell>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold" }}
              >
                Time Logged (hrs)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((project, projectIndex) => (
              <React.Fragment key={`project_${projectIndex}`}>
                {project.assignees.map((assignee, assigneeIndex) =>
                  assignee.issues.map((issue, issueIndex) =>
                    issue.workItems.map((workItem, workIndex) => (
                      <TableRow
                        key={`${project.project}_${assignee.name}_${issue.monthYear}_${workItem.name}_${workIndex}`}
                      >
                        {workIndex === 0 &&
                          assigneeIndex === 0 &&
                          issueIndex === 0 && (
                            <TableCell
                              rowSpan={project.assignees.reduce(
                                (acc, currAssignee) =>
                                  acc +
                                  currAssignee.issues.reduce(
                                    (issueAcc, currIssue) =>
                                      issueAcc + currIssue.workItems.length,
                                    0
                                  ),
                                0
                              )}
                              className="table-cell"
                            >
                              {capitalize(project.project)}
                            </TableCell>
                          )}
                        {workIndex === 0 && issueIndex === 0 && (
                          <TableCell
                            rowSpan={assignee.issues.reduce(
                              (acc, currIssue) =>
                                acc + currIssue.workItems.length,
                              0
                            )}
                            className="table-cell"
                          >
                            {capitalize(assignee.name)}
                          </TableCell>
                        )}
                        {workIndex === 0 && issue.monthYear !== "Total" && (
                          <TableCell
                            rowSpan={issue.workItems.length}
                            className="table-cell"
                          >
                            {issue.monthYear !== "NaN" ? issue.monthYear : ""}
                          </TableCell>
                        )}
                        {workIndex === 0 && issue.monthYear === "Total" && (
                          <TableCell
                            colSpan={3}
                            className="table-cell"
                            style={{
                              fontWeight: 600,
                              // textAlign:"right",
                              paddingRight: "20px",
                            }}
                          >
                            <b>{`${capitalize(assignee.name)} Sub Total`}</b>
                          </TableCell>
                        )}
                        {issue.monthYear !== "Total" && (
                          <TableCell
                            className="table-cell"
                            style={{
                              fontWeight:
                                workItem.name === "Total" ? 600 : "normal",
                              paddingRight: "20px",
                              width: workItem.name === "Total" ? "" : "500px",
                            }}
                            colSpan={workItem.name === "Subtotal" ? 2 : 1}
                          >
                            {workItem.name !== "Subtotal" ? (
                              workItem.name && (
                                <>
                                  {!project.jiraReference ? (
                                    <>
                                      <a
                                        href={`https://divami.atlassian.net/browse/${workItem.issueKey}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "blue" }}
                                      >
                                        {workItem.issueKey}
                                      </a>
                                      {workItem.issueKey ? " - " : ""}
                                    </>
                                  ) : (
                                    capitalize(workItem.name)
                                  )}
                                  {capitalize(workItem.name)}
                                </>
                              )
                            ) : (
                              <b>{issue.monthYear} Sub Total</b>
                            )}
                          </TableCell>
                        )}
                        {issue.monthYear !== "Total" &&
                          workItem.name !== "Subtotal" && (
                            <TableCell className="table-cell">
                              {workItem.date !== "NaN-Invalid Date-NaN"
                                ? workItem.date
                                : ""}
                            </TableCell>
                          )}
                        {(issue.monthYear === "Total" ||
                          workItem.name === "Subtotal") && (
                          <TableCell
                            className="table-cell"
                            style={{ textAlign: "right", paddingRight: "10px" }}
                          >
                            {!isNaN(workItem.time) ? (
                              <b>{workItem.time.toFixed(2)} hrs</b>
                            ) : (
                              0
                            )}
                          </TableCell>
                        )}
                        {issue.monthYear !== "Total" &&
                          workItem.name !== "Subtotal" && (
                            <TableCell
                              className="table-cell"
                              style={{
                                textAlign: "right",
                                paddingRight: "10px",
                              }}
                            >
                              {!isNaN(workItem.time)
                                ? workItem.time.toFixed(2)
                                : 0}
                            </TableCell>
                          )}
                      </TableRow>
                    ))
                  )
                )}
                <TableRow>
                  {/*
                    It will be used further to display no of business working days -Madhuri
                    */}
                  {/* <TableCell
                    className="table-cell"
                    colSpan={4}
                    style={{ fontWeight: "bold" }}
                  >{` ${project.project} Sub Total`}</TableCell>
                  <TableCell
                    className="table-cell"
                    style={{ fontWeight: "bold" }}
                  >{`Working Days: ${project.workingDays}`}</TableCell> */}
                  <TableCell
                    className="table-cell"
                    colSpan={5}
                    style={{ fontWeight: "bold" }}
                  >{` ${capitalize(project.project)} Sub Total`}</TableCell>
                  <TableCell
                    className="table-cell"
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                  >{` ${project.totalHours.toFixed(2)} hrs`}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default ProjectTable;
