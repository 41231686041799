import React, { useState, useEffect } from "react";
import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { useStyles, MenuProps } from "../../utils/MultiSelect";
import "./index.scss";

interface QuickFilterComponentProps {
  setSelectedDates: React.Dispatch<
    React.SetStateAction<{
      startDate: string | null;
      endDate: string | null;
    }>
  >;
  selectedRange: string;
  isDisabled?:boolean;
  setSelectedRange: React.Dispatch<React.SetStateAction<string>>;
}

// Predefined date ranges for the select menu
const dateRanges = [
  { label: "1 Day", value: "1day" },
  { label: "Previous Week", value: "previousWeek" },
  { label: "Current Week", value: "currentWeek" },
  { label: "1 Week", value: "1week" },
  { label: "1 Month", value: "1month" },
  { label: "Last 6 Months", value: "6months" },
  { label: "1 Year", value: "1year" },
];

const QuickFilterComponent: React.FC<QuickFilterComponentProps> = ({
  setSelectedDates,
  selectedRange,
  setSelectedRange,
  isDisabled
}) => {
  const classes = useStyles();


  useEffect(() => {
    setSelectedDates({ startDate: null, endDate: null });
    handleDateRangeChange(selectedRange); // Add this line to set the initial date range
  }, [setSelectedDates]);
  
  // Function to handle the change in selected date range
  const handleDateRangeChange = (range: string) => {
    setSelectedRange(range);

    const today = new Date();
    let startDate: Date;
    let endDate: Date = new Date();
    // Determine the start date based on the selected range

    switch (range) {
      case "1year":
        startDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case "6months":
        startDate = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case "1month":
        startDate = new Date(today.setMonth(today.getMonth() - 1));
        break;
      case "1week":
        startDate = new Date(today.setDate(today.getDate() - 7));
        break;
      case "1day":
        startDate = new Date(today.setDate(today.getDate() - 1));
        break;
      case "currentWeek":
        startDate = new Date(today.setDate(today.getDate() + (today.getDay() === 6 ? 0 : - (today.getDay() + 1) )))
        endDate = new Date(startDate);
        endDate = new Date(endDate.setDate(endDate.getDate() + 6));
        break;
      case "previousWeek":
        startDate = new Date(today.setDate(today.getDate() + (today.getDay() === 6 ? -7 : - (today.getDay() + 8) )))
        endDate = new Date(startDate);
        endDate = new Date(endDate.setDate(endDate.getDate() + 6));
        break;
      default:
        startDate = new Date(0);
    }

    setSelectedDates({
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
    });
  };

  return (
    <FormControl variant="standard">
      <InputLabel id="quick-filter-select-label">Quick Filter</InputLabel>
      <Select
        labelId="quick-filter-select-label"
        id="select"
        value={selectedRange}
        onChange={(e) => handleDateRangeChange(e.target.value as string)}
        className={`quick-filter-select-label ${isDisabled ? 'disabled' : ''}`}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {dateRanges.map((range) => (
          <MenuItem key={range.value} value={range.value}>
            {range.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default QuickFilterComponent;
