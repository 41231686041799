import { Tooltip } from "@mui/material";

export const trimAndTooltip = (name: string, length: number) => {
  if (!name || name.length <= length) {
    return <span>{name}</span>;
  }
  
  const trimmedName = `${name.slice(0, length)}...`;
  
  return (
    <Tooltip title={name}>
      <span>{trimmedName}</span>
    </Tooltip>
  );
};
