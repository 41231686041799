import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Grid,
  Typography,
  Paper,
  Container,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Autocomplete,
  IconButton,
} from "@mui/material";
import "./index.scss";
import {
  Assignee,
  Project,
  ProjectData,
  createProject,
  getAllAssignees,
  getAllProjects,
  getProjectById,
  getProjectWiseAssignees,
  updateProject,
} from "common/api/ApiService";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

interface FormData {
  name: string;
  description: string;
  projectTypeId: number;
  startDate: Date | null;
  endDate: Date | null;
  totalQuote: number | null;
  totalQuotedEfforts: number | null;
  jiraProjectIdExt: string;
  leadName: string;
  leadAccountId: string;
  departmentId: number | null;
  projectOwnerId: number | null;
  projectManagerId: number | null;
  technicalLeadId: number | null;
  deliveryManagerId: number | null;
  projectedEndDate: Date | null;
  projectedEfforts: number | null;
  projectedTotalQuote: number | null;
  customerSatisfaction: string | null;
  teamSatisfaction: string | null;
  criticality: string | null;
  sowEndDate: Date | null;
  flcWeekly: number;
  projectStatusId: number;
}

const steps = ["Project Details", "Project Timing & Cost", "Team Members"];

const projectTypeOptions = [
  { value: 0, label: "Select Project Type", disabled: true },
  { value: 1, label: "T&M" },
  { value: 2, label: "Fixed" },
  { value: 3, label: "T&M(Cap)" },
];
const gradeOptions = [
  { value: "", label: "Select Grade", disabled: true },
  { value: "A+", label: "A+" },
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
];

const criticalOptions = [
  { value: "", label: "Select Criticality", disabled: true },
  { value: "Diamond", label: "Diamond" },
  { value: "Platinum", label: "Platinum" },
  { value: "Premium", label: "Premium" },
];

const initialFormState: FormData = {
  name: "",
  leadName: "",
  leadAccountId: "",
  description: "",
  projectTypeId: 0,
  startDate: null,
  endDate: null,
  totalQuote: 0,
  totalQuotedEfforts: 0,
  jiraProjectIdExt: "",
  departmentId: 0,
  projectOwnerId: 0,
  projectManagerId: 0,
  technicalLeadId: 0,
  deliveryManagerId: 0,
  projectedEndDate: null,
  projectedEfforts: null,
  projectedTotalQuote: null,
  customerSatisfaction: "",
  teamSatisfaction: "",
  criticality: "",
  sowEndDate: null,
  flcWeekly: 0,
  projectStatusId: 0,
};

const isStepValid = (step: number, formData: FormData) => {
  switch (step) {
    case 0:
      return (
        formData.name !== "" &&
        formData.jiraProjectIdExt !== "" &&
        formData.departmentId !== 0 &&
        formData.criticality !== ""
      );
    case 1:
      return (
        formData.projectTypeId !== 0 &&
        formData.projectStatusId !== 0 &&
        formData.totalQuote !== 0 &&
        formData.totalQuotedEfforts !== 0 &&
        formData.flcWeekly !== 0
      );
    case 2:
      return true;
    default:
      return false;
  }
};
interface UpdateProjectFormProps {
  selectedProjectId: string;
  setSelectedProjectId: (projectId: string) => void;
  isEdit: boolean;
  setIsEdit: (isEdit: boolean) => void;
}

const UpdateProjectForm: React.FC<UpdateProjectFormProps> = ({
  selectedProjectId,
  setSelectedProjectId,
  isEdit,
  setIsEdit,
}) => {
  const [assigneeData, setAssigneeData] = useState<Assignee[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDateError, setShowDateError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [projectsList, setProjectsList] = useState<ProjectData[]>([]);
  const [projectAssignees, setProjectAssignees] = useState<Assignee[]>([]);
  const navigate = useNavigate();
  const projectStatusOptions = [
    { label: "Select Project Status", value: 0, disabled: true },
    { label: "Not started", value: 1 },
    { label: "In progress", value: 2 },
    { label: "Hold", value: 3 },
    { label: "Closed", value: 4 },
  ];
  // console.log("id", selectedProjectId);
  const departmentOptions = [
    { label: "select department option", value: 0, disabled: true },
    { label: "Design", value: 1 },
    { label: "Development", value: 2 },
    { label: "Design + Development", value: 3 },
  ];

  const showAlertMessage = (type: "success" | "error", message: string) => {
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);
  };

  useEffect(() => {
    const fetchAssigneeData = async () => {
      try {
        const data = await getAllAssignees();
        setAssigneeData(data);
      } catch (error) {
        console.error("Error while fetching assignee data", error);
      }
    };
    fetchAssigneeData();
  }, []);

  useEffect(() => {
    if (selectedProjectId) {
      const fetchProjectAssigneeData = async () => {
        try {
          const data = await getProjectWiseAssignees(
            parseInt(selectedProjectId)
          );
          setProjectAssignees(data);
          // console.log(data);
        } catch (error) {
          console.error("Error while fetching assignee data", error);
        }
      };
      fetchProjectAssigneeData();
    }
  }, [selectedProjectId]);

  // useEffect(() => {
  //   const fetchProjectList = async () => {
  //     try {
  //       const data = await getAllProjects();
  //       setProjectsList(data);
  //     } catch (error) {
  //       console.error("Error while fetching project data", error);
  //     }
  //   };
  //   fetchProjectList();
  // }, []);

  useEffect(() => {
    if (selectedProjectId !== "") {
      const fetchProjectList = async () => {
        try {
          const data = await getProjectById(parseInt(selectedProjectId));
          setProjectsList(data);
          const selectedProject = data[0];
          if (selectedProject) {
            setFormData({
              ...initialFormState,
              name: selectedProject.name || initialFormState.name,
              jiraProjectIdExt:
                selectedProject.jiraProjectIdExt ||
                initialFormState.jiraProjectIdExt,
              leadName: selectedProject.leadName || initialFormState.leadName,
              leadAccountId:
                selectedProject.leadAccountId || initialFormState.leadAccountId,
              description:
                selectedProject.description || initialFormState.description,
              projectTypeId:
                selectedProject.projectTypeId || initialFormState.projectTypeId,
              startDate:
                selectedProject.startDate || initialFormState.startDate,
              endDate: selectedProject.endDate || initialFormState.endDate,
              totalQuote:
                Number(selectedProject.totalQuote) ||
                Number(initialFormState.totalQuote),
              totalQuotedEfforts:
                Number(selectedProject.totalQuotedEfforts) ||
                Number(initialFormState.totalQuotedEfforts),
              technicalLeadId: selectedProject.technicalLeadId || null,
              projectManagerId: selectedProject.projectManagerId || null,
              deliveryManagerId: selectedProject.deliveryManagerId || null,
              projectOwnerId: selectedProject.projectOwnerId || null,
              departmentId:
                selectedProject.departmentId || initialFormState.departmentId,
              customerSatisfaction:
                selectedProject.customerSatisfaction ||
                initialFormState.customerSatisfaction,
              teamSatisfaction:
                selectedProject.teamSatisfaction ||
                initialFormState.teamSatisfaction,
              projectStatusId:
                selectedProject.projectStatusId ||
                initialFormState.projectStatusId,
              criticality:
                selectedProject.criticality || initialFormState.criticality,
              sowEndDate:
                selectedProject.sowEndDate || initialFormState.sowEndDate,
              flcWeekly:
                Number(selectedProject.flcWeekly) ||
                Number(initialFormState.flcWeekly),
            });
          }
        } catch (error) {
          console.error("Error while fetching project data", error);
        }
      };
      fetchProjectList();
    }
  }, [selectedProjectId]);

  const handleNext = () => {
    if (isStepValid(activeStep, formData)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  }, [showAlert]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let updatedValue: string | number | Date = value;

    if (name === "totalQuotedEfforts" || name === "totalQuote") {
      updatedValue =
        value === "" || isNaN(Number(value)) ? "" : parseFloat(value);
    } else if (
      name === "projectManagerId" ||
      name === "technicalLeadId" ||
      name === "productOwnerId" ||
      name === "projectStatusId" ||
      name === "projectTypeId" ||
      name === "deliveryManagerId"
    ) {
      updatedValue =
        value === "" || isNaN(Number(value)) ? "" : parseInt(value, 10);
    } else if (name === "startDate" || name === "endDate") {
      updatedValue = value;

      if (name === "startDate") {
        const startDate = new Date(value);
        const endDate = new Date(formData.endDate ?? new Date());
        if (isNaN(startDate.getTime())) {
          setShowDateError(true);
          return;
        } else {
          setShowDateError(false);
        }
        if (
          formData.endDate &&
          endDate &&
          !isNaN(endDate.getTime()) &&
          startDate > endDate
        ) {
          showAlertMessage(
            "error",
            "start Date should not be greater than end date"
          );
          return;
        }
      } else if (name === "endDate") {
        const endDate = new Date(value);
        const startDate = new Date(formData.startDate ?? new Date());
        if (isNaN(endDate.getTime())) {
          showAlertMessage("error", "end Date should be a valid date");
          return;
        }
        if (
          formData.startDate &&
          startDate &&
          !isNaN(startDate.getTime()) &&
          endDate < startDate
        ) {
          showAlertMessage(
            "error",
            `${name} should not be less than start date`
          );
          return;
        }
      }
    } else if (name === "leadName") {
      const selectedLeadName = value;

      const selectedLead = projectAssignees.find(
        (lead) => lead.first_name === selectedLeadName
      );
      if (selectedLead) {
        setFormData((prevState) => ({
          ...prevState,
          leadName: selectedLead.first_name,
          leadAccountId: selectedLead.assignee_jira_account_id_ext,
        }));
      }
    }

    const updatedFormData = {
      ...formData,
      [name]: updatedValue,
    };
    setFormData(updatedFormData);
  };

  // const formatDate = (date: Date): string => {
  //   // const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const day = date.getDate().toString().padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };

  // useEffect(() => {
  //   const fetchProjects = async () => {
  //     const fetchedProjects = await getAllProjects();
  //     setProjects(fetchedProjects);
  //   };

  //   fetchProjects();
  // }, []);

  const handleSubmit = async () => {
    try {
      // console.log(projects);
      const projectExists = projects.some(
        (project) => project.jiraProjectIdExt === formData.jiraProjectIdExt
      );
      // console.log(parseInt(formData.jiraProjectIdExt));
      // console.log(projectExists);
      if (projectExists) {
        setShowConfirmDialog(true);
        return;
      }
      // console.log(formData);
      // handleFormSubmit();
      showAlertMessage("success", "Project created successfully");
      handleReset();
      navigate("/project-iq/dashboard");
    } catch (error) {
      throw new Error("Error at creating project");
      showAlertMessage("error", "Error while creating project");
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData(initialFormState);
  };

  const handleContinue = () => {
    setShowConfirmDialog(false);
    // setProjects([...projects, formData]);
    // console.log(formData);
    // handleFormSubmit();
    handleReset();
    navigate("/project-iq/dashboard");
  };

  const assigneeOptions = [
    { assignee_id: 0, first_name: "Select an assignee" },
    ...assigneeData,
  ];
  const handleFormSubmit = async () => {
    const formattedData = {
      ...formData,
      startDate: new Date(formData.startDate ?? new Date()),
      endDate: new Date(formData.endDate ?? new Date()),
      jiraProjectIdExt: formData.jiraProjectIdExt.toString(),
    };
    // console.log("formatted", formattedData);
    try {
      // await createProject(formattedData);
      // await updateProject(formattedData, parseInt(selectedProjectId));
    } catch (error) {
      console.error("error while creating project");
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (showDateError) {
      timeoutId = setTimeout(() => {
        setShowDateError(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showDateError]);

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Project Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
                className="text-field"
                InputProps={{
                  style: {
                    color: formData.name === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="JIRA Project ID Ext"
                name="jiraProjectIdExt"
                value={formData.jiraProjectIdExt}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.jiraProjectIdExt === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color: formData.description === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="date"
                label="Start Date"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color: formData.startDate === null ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="date"
                label="End Date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color: formData.endDate === null ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Department"
                name="departmentId"
                value={formData.departmentId}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{
                  style: {
                    color: formData.departmentId === 0 ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
                className="text-field"
              >
                {departmentOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Criticality"
                name="criticality"
                value={formData.criticality}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color: formData.criticality === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              >
                {criticalOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                label="Project Type"
                name="projectTypeId"
                value={formData.projectTypeId}
                onChange={handleChange}
                fullWidth
                required
                className="text-field"
                InputProps={{
                  style: {
                    color: formData.projectTypeId === 0 ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              >
                {projectTypeOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Project Status"
                name="projectStatusId"
                value={formData.projectStatusId}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color: formData.projectStatusId === 0 ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              >
                {projectStatusOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Billable Rate"
                name="totalQuote"
                value={formData.totalQuote}
                onChange={handleChange}
                fullWidth
                required
                type="number"
                className="text-field"
                InputProps={{
                  style: {
                    color: formData.totalQuote === 0 ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Quoted Time"
                name="totalQuotedEfforts"
                value={formData.totalQuotedEfforts}
                onChange={handleChange}
                fullWidth
                required
                type="number"
                className="text-field"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color:
                      formData.totalQuotedEfforts === 0 ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="FLC Weekly"
                name="flcWeekly"
                value={formData.flcWeekly}
                onChange={handleChange}
                fullWidth
                type="number"
                className="text-field"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color: formData.flcWeekly === 0 ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="SOW End Date"
                name="sowEndDate"
                value={formData.sowEndDate}
                onChange={handleChange}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                className="text-field"
                InputProps={{
                  style: {
                    color: formData.sowEndDate === null ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                select
                label="Customer Satisfaction"
                name="customerSatisfaction"
                value={formData.customerSatisfaction}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.customerSatisfaction === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              >
                {gradeOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Team Satisfaction"
                name="teamSatisfaction"
                value={formData.teamSatisfaction}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.teamSatisfaction === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              >
                {gradeOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                options={projectAssignees}
                getOptionLabel={(option) => option.first_name}
                value={
                  projectAssignees.find(
                    (assignee) => assignee.first_name === formData.leadName
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    leadName: newValue?.first_name || "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lead Name"
                    name="leadName"
                    fullWidth
                    className="text-field"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Lead Account ID"
                name="leadAccountId"
                value={formData.leadAccountId}
                onChange={handleChange}
                fullWidth
                className="text-field"
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={assigneeData}
                getOptionLabel={(option) => option.first_name}
                value={
                  assigneeData.find(
                    (assignee) =>
                      assignee.assignee_id === formData.technicalLeadId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    technicalLeadId: newValue?.assignee_id || 0,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Technical Lead"
                    fullWidth
                    className="text-field"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={assigneeData}
                getOptionLabel={(option) => option.first_name}
                value={
                  assigneeData.find(
                    (assignee) =>
                      assignee.assignee_id === formData.projectOwnerId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    projectOwnerId: newValue?.assignee_id || 0,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Owner"
                    fullWidth
                    className="text-field"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={assigneeData}
                getOptionLabel={(option) => option.first_name}
                value={
                  assigneeData.find(
                    (assignee) =>
                      assignee.assignee_id === formData.projectManagerId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    projectManagerId: newValue?.assignee_id || 0,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Manager"
                    fullWidth
                    className="text-field"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={assigneeData}
                getOptionLabel={(option) => option.first_name}
                value={
                  assigneeData.find(
                    (assignee) =>
                      assignee.assignee_id === formData.deliveryManagerId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    deliveryManagerId: newValue?.assignee_id || 0,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Delivery Manager"
                    fullWidth
                    className="text-field"
                  />
                )}
              />
            </Grid>
          </Grid>
        );

      default:
        return "unKnown Step";
    }
  };

  return (
    <Container maxWidth="md" className="form-container">
      {showAlert && (
        <Alert
          severity={alertType}
          className="floating-alert"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setShowAlert(false)}
            >
              <IoCloseCircleOutline fontSize="inherit" />
            </IconButton>
          }
        >
          {alertMessage}
        </Alert>
      )}

      <Paper className="paper-container">
        <h1 className="form-heading">Update Project</h1>
        <Stepper activeStep={activeStep} className="stepper-container">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Typography variant="h5" align="center">
            Thank you for your submission!
          </Typography>
        ) : (
          <>
            {renderStepContent(activeStep)}
            <div className="button-container">
              {activeStep !== 0 && <Button onClick={handleBack}>Back</Button>}

              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={!isStepValid(activeStep, formData)}
                >
                  Next
                </Button>
              )}
            </div>
          </>
        )}
      </Paper>
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Project Already Exists
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to continue and update the project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)} color="primary">
            Back
          </Button>
          <Button onClick={handleContinue} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UpdateProjectForm;
