import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  postTimesheetData,
  timesheetDeafaultersData,
} from "common/api/ApiService";
import moment from "moment-business-days";
import "./index.scss";
import { capitalize } from "utils/Capitalize";

interface ProjectData {
  firstName: string;
  lastName: string | null;
  departmentId: number;
  hasJiraAccess: number;
  assigneeId: number;
  issues: {
    name: string;
    description: string | null;
    issueId: number;
    projectId: number;
    timeLogs: {
      loggedTime: number;
      comments: string | null;
      loggedAt: string;
    }[];
  }[];
}

interface WorkItem {
  name: string;
  time: number;
  date: string;
}

interface AssigneeData {
  name: string;
  issues: {
    monthYear: string;
    workItems: WorkItem[];
  }[];
}

interface TimeSheetDefaultersProps {
  payload: Payload;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Payload {
  projectId: number[];
  fromDate: string;
  toDate: string;
}

interface TimesheetDeafaulterPayload {
  startDate: string;
  endDate: string;
  projectId: number[];
}

interface SortConfig {
  key: string;
  direction: "asc" | "desc" | undefined;
}

const TimeSheetDefaulters: React.FC<TimeSheetDefaultersProps> = ({
  payload,
  loading,
  setLoading,
}) => {
  const [filteredData, setFilteredData] = useState<AssigneeData[]>([]);
  const [allDates, setAllDates] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: "",
    direction: undefined,
  });
  const [clickCount, setClickCount] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const projects: ProjectData[] = await timesheetDeafaultersData(payload);
        const data = filterProjectData(projects);
        setFilteredData(data);
        // console.log("data", data);
        generateAllDates(payload.fromDate, payload.toDate);
      } catch (error) {
        console.error("Error fetching projects data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [payload]);

  const generateAllDates = (fromDate: string, toDate: string) => {
    const startDate = moment(fromDate);
    const endDate = moment(toDate);
    const dates: string[] = [];

    while (startDate <= endDate) {
      dates.push(startDate.format("DD/MM/YYYY"));
      startDate.add(1, "day");
    }

    setAllDates(dates);
  };

  const filterProjectData = (projects: ProjectData[]): AssigneeData[] => {
    const assigneesMap = new Map<string, AssigneeData>();
    projects.forEach((project) => {
      if (project) {
        const assigneeName = project.firstName ? project.firstName : "";
        let assigneeData = assigneesMap.get(assigneeName);

        if (!assigneeData) {
          assigneeData = { name: assigneeName, issues: [] };
          assigneesMap.set(assigneeName, assigneeData);
        }
        project.issues.forEach((issue) => {
          const assigneeName = project.firstName ? project.firstName : "";

          if (assigneeName !== "") {
            let assigneeData = assigneesMap.get(assigneeName);

            if (!assigneeData) {
              assigneeData = { name: assigneeName, issues: [] };
              assigneesMap.set(assigneeName, assigneeData);
            }

            issue.timeLogs.forEach((timeLog) => {
              const createdAt = new Date(timeLog.loggedAt);
              const day = String(createdAt.getDate()).padStart(2, "0");
              const month = String(createdAt.getMonth() + 1).padStart(2, "0");
              const year = createdAt.getFullYear();
              const formattedDate = `${day}/${month}/${year}`;

              if (assigneeData) {
                let existingMonthYearIssue = assigneeData.issues.find(
                  (i) => i.monthYear === formattedDate
                );

                if (!existingMonthYearIssue) {
                  existingMonthYearIssue = {
                    monthYear: formattedDate,
                    workItems: [],
                  };
                  assigneeData.issues.push(existingMonthYearIssue);
                }

                const workItem: WorkItem = {
                  name: issue.name,
                  time: Number(timeLog.loggedTime),
                  date: formattedDate,
                };

                existingMonthYearIssue.workItems.push(workItem);
              }
            });
          }
        });
      }
    });

    const assigneeArray = Array.from(assigneesMap.values());

    assigneeArray.sort((a, b) => a.name.localeCompare(b.name));

    return assigneeArray;
  };

  const handleSort = (key: string) => {
    const newClickCount = clickCount[key] ? clickCount[key] + 1 : 1;
    const newDirection =
      newClickCount === 1 ? "asc" : newClickCount === 2 ? "desc" : undefined;

    if (newClickCount % 3 === 0) {
      setSortConfig({ key: "", direction: undefined });
    } else {
      setSortConfig({ key, direction: newDirection });
    }
    setClickCount((prev) => ({ ...prev, [key]: newClickCount % 3 }));
  };

  const sortedData = React.useMemo(() => {
    // console.log("sortConfig", sortConfig);
    if (sortConfig.direction === undefined) {
      return filteredData;
    }
    if (sortConfig.key === "totalHours") {
      return [...filteredData].sort((a, b) => {
        const aTotal = a.issues.reduce(
          (total, issue) =>
            total + issue.workItems.reduce((sum, item) => sum + item.time, 0),
          0
        );
        const bTotal = b.issues.reduce(
          (total, issue) =>
            total + issue.workItems.reduce((sum, item) => sum + item.time, 0),
          0
        );
        return sortConfig.direction === "asc"
          ? aTotal - bTotal
          : bTotal - aTotal;
      });
    } else if (sortConfig.key) {
      return [...filteredData].sort((a, b) => {
        const aTotal = a.issues.reduce(
          (total, issue) =>
            total +
            issue.workItems.reduce(
              (sum, item) =>
                sum + (item.date === sortConfig.key ? item.time : 0),
              0
            ),
          0
        );
        const bTotal = b.issues.reduce(
          (total, issue) =>
            total +
            issue.workItems.reduce(
              (sum, item) =>
                sum + (item.date === sortConfig.key ? item.time : 0),
              0
            ),
          0
        );
        return sortConfig.direction === "asc"
          ? aTotal - bTotal
          : bTotal - aTotal;
      });
    }
    return filteredData;
  }, [filteredData, sortConfig]);

  return (
    <div className="project-table-container">
      <TableContainer component={Paper}>
        {!loading && filteredData.length === 0 ? (
          <Typography variant="h6" align="center" className="empty-records">
            No records found
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="sticky-col name-col-heading">
                  Name
                </TableCell>
                {allDates.map((date, index) => (
                  <TableCell
                    key={index}
                    className="date-col-heading"
                    sortDirection={
                      sortConfig.key === date ? sortConfig.direction : false
                    }
                  >
                    <TableSortLabel
                      active={sortConfig.key === date}
                      direction={
                        sortConfig.key === date ? sortConfig.direction : "asc"
                      }
                      onClick={() => handleSort(date)}
                      style={{
                        color: sortConfig.key === date ? "orange" : undefined,
                      }}
                    >
                      {moment(date, "DD/MM/YYYY").format("DD MMM YYYY")}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell
                  className="date-col-heading"
                  sortDirection={
                    sortConfig.key === "totalHours"
                      ? sortConfig.direction
                      : false
                  }
                >
                  <TableSortLabel
                    active={sortConfig.key === "totalHours"}
                    direction={
                      sortConfig.key === "totalHours"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("totalHours")}
                    style={{
                      color:
                        sortConfig.key === "totalHours" ? "orange" : undefined,
                    }}
                  >
                    Total Hours
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((assignee) => (
                <TableRow key={assignee.name} className="table-row-defaulters">
                  <TableCell className="sticky-col name-col-name">
                    {capitalize(assignee.name)}
                  </TableCell>
                  {allDates.map((date, index) => {
                    const totalHoursForDate = assignee.issues.reduce(
                      (total, issue) => {
                        const workItemsForDate = issue.workItems.filter(
                          (item) => item.date === date
                        );

                        const timeForDate = workItemsForDate.reduce(
                          (sum, item) => sum + item.time,
                          0
                        );
                        return total + timeForDate;
                      },
                      0
                    );

                    return (
                      <TableCell
                        key={index}
                        className={`table-cell-resource ${
                          totalHoursForDate === 0 ? "empty-cell" : ""
                        }`}
                        style={{ textAlign: "right" }}
                      >
                        {totalHoursForDate !== 0
                          ? totalHoursForDate % 1 === 0
                            ? totalHoursForDate.toFixed(2)
                            : totalHoursForDate.toFixed(2)
                          : totalHoursForDate.toFixed(2)}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    className="table-cell-resource"
                    style={{ textAlign: "right" }}
                  >
                    {assignee.issues.reduce(
                      (total, issue) =>
                        total +
                        issue.workItems.reduce(
                          (sum, item) => sum + item.time,
                          0
                        ),
                      0
                    ) %
                      1 ===
                    0
                      ? assignee.issues
                          .reduce(
                            (total, issue) =>
                              total +
                              issue.workItems.reduce(
                                (sum, item) => sum + item.time,
                                0
                              ),
                            0
                          )
                          .toFixed(2)
                      : assignee.issues
                          .reduce(
                            (total, issue) =>
                              total +
                              issue.workItems.reduce(
                                (sum, item) => sum + item.time,
                                0
                              ),
                            0
                          )
                          .toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default TimeSheetDefaulters;
