import React, { createContext, useReducer, useContext, ReactNode } from "react";

interface GlobalState {
  tokenExpired: boolean;
}

interface GlobalStateProviderProps {
  children: ReactNode;
}

const initialState: GlobalState = {
  tokenExpired: false,
};

// Create contexts for global state and dispatch
const GlobalStateContext = createContext<GlobalState>(initialState);
const GlobalDispatchContext = createContext<React.Dispatch<any>>(() => null);

// Define the reducer function to manage state updates
const reducer = (state: GlobalState, action: { type: string; payload: any }): GlobalState => {
  switch (action.type) {
    case "SET_TOKEN_EXPIRED":
      return {
        ...state,
        tokenExpired: action.payload,
      };
    default:
      return state;
  }
};

// Create the GlobalStateProvider component to provide state and dispatch to its children
export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

// Custom hooks to use the global state and dispatch in other components
export const useGlobalState = () => useContext(GlobalStateContext);
export const useGlobalDispatch = () => useContext(GlobalDispatchContext);
