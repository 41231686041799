import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  getSyncRequest,
  updateSyncRequestStatusByRequestId,
} from "common/api/ApiService";
import { IoArrowBack, IoArrowDown, IoArrowUp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "components/DashboardHeader";
import "./index.scss";
import { trimAndTooltip } from "utils/TrimandToolTip";
import { convertToIST } from "utils/ConvertToIST";
import { SyncRequest } from "models/ISyncReport";
import { FaFilter } from "react-icons/fa";

const SyncRequestTable: React.FC = () => {
  const [syncRequestData, setSyncRequestData] = useState<SyncRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollDirection] = useState<"down" | "up">("down");
  const [syncUpdated, setSyncUpdated] = useState(false);
  const [selectedSyncStatus, setSelectedSyncStatus] = useState<number | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [selectedSyncStatus, setSelectedSyncStatus] = useState<number | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: number | null) => {
    setSelectedSyncStatus(value);
    handleMenuClose();
  };
  useEffect(() => {
    const fetchSyncRequestData = async () => {
      setLoading(true);
      setSyncUpdated(false);
      try {
        const resp = await getSyncRequest();
        setSyncRequestData(resp);
      } catch (error) {
        console.error("Error fetching sync request data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSyncRequestData();
  }, [syncUpdated]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (requestId: number | null, row: SyncRequest) => {
    if (requestId) {
      const url = `/project-iq/sync-report-details/${requestId}`;
      window.open(url, "_blank");
    }
  };

  const handleCompleteSync = async (requestId: number) => {
    const resp = await updateSyncRequestStatusByRequestId(requestId);
    if (resp.statusCode === 200 || resp.statusCode === 201) {
      setSyncUpdated(true);
    }
  };
  const getSyncRequestTypeName = (syncType: number): string => {
    if (syncType === 1) {
      return "Scheduler";
    } else if (syncType === 2) {
      return "Manual";
    } else if (syncType === 3) {
      return "Webhook";
    }
    return "Unknown";
  };

  const getSyncStatusTypeName = (status: number): string => {
    if (status === 1) {
      return "Pending";
    } else if (status === 2) {
      return "In Progress";
    } else if (status === 3) {
      return "Success";
    } else if (status === 4) {
      return "Failure";
    } else if (status === 5) {
      return "Cancelled";
    }
    return "Unknown";
  };

  const sortedSyncRequestData = [...syncRequestData].sort(
    (a, b) => new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime()
  );

  const filteredSyncRequestData = selectedSyncStatus
    ? sortedSyncRequestData.filter(
        (request) => parseInt(request.status) === selectedSyncStatus
      )
    : sortedSyncRequestData;

  const getStatusColor = (status: number): string => {
    switch (status) {
      case 1:
        return "gray";
      case 2:
        return "blue";
      case 3:
        return "green";
      case 4:
        return "red";
      case 5:
        return "orange";
      default:
        return "black";
    }
  };

  const handleScrollButtonClick = () => {
    if (tableContainerRef.current) {
      if (scrollDirection === "down") {
        tableContainerRef.current.scrollTop =
          tableContainerRef.current.scrollHeight;
        setScrollDirection("up");
      } else {
        tableContainerRef.current.scrollTop = 0;
        setScrollDirection("down");
      }
    }
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <DashboardHeader />
      <TableContainer
        component={Paper}
        className="sync-report-table-container"
        ref={tableContainerRef}
      >
        <Typography variant="h6" gutterBottom>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <span
              onClick={() => navigate("/")}
              style={{
                marginTop: "5px",
                marginBottom: "2px",
                paddingLeft: "3px",
                cursor: "pointer",
              }}
              aria-label="Go back"
              role="button"
            >
              <IoArrowBack size={24} />
            </span>
            <h3 style={{ margin: "0 auto" }}>Sync Report</h3>
          </div>
        </Typography>

        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Serial No
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Request By Assignee
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Sync Type
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Sync Status
                <IconButton
                  onClick={handleMenuOpen}
                  style={{ margin: "0 10px" }}
                >
                  <IconButton
                    onClick={handleMenuOpen}
                    style={{ margin: "0 10px" }}
                  >
                    <FaFilter
                      style={
                        selectedSyncStatus! == null
                          ? { fontSize: "16px" }
                          : { fontSize: "16px", color: "orange" }
                      }
                    />
                  </IconButton>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleMenuItemClick(null)}>
                    All Statuses
                  </MenuItem>
                  <MenuItem
                    style={
                      selectedSyncStatus === 1
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                    onClick={() => handleMenuItemClick(1)}
                  >
                    Pending
                  </MenuItem>
                  <MenuItem
                    style={
                      selectedSyncStatus === 2
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                    onClick={() => handleMenuItemClick(2)}
                  >
                    In Progress
                  </MenuItem>
                  <MenuItem
                    style={
                      selectedSyncStatus === 3
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                    onClick={() => handleMenuItemClick(3)}
                  >
                    Success
                  </MenuItem>
                  <MenuItem
                    style={
                      selectedSyncStatus === 4
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                    onClick={() => handleMenuItemClick(4)}
                  >
                    Failure
                  </MenuItem>
                  <MenuItem
                    style={
                      selectedSyncStatus === 5
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                    onClick={() => handleMenuItemClick(5)}
                  >
                    Cancelled
                  </MenuItem>
                </Menu>
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Message
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Started
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Completed
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Projects Count
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f5f5",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSyncRequestData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  style={{ borderBottom: "1px solid #ddd" }}
                >
                  <TableCell
                    style={{ border: "1px solid #ddd", cursor: "pointer" }}
                    onClick={() => handleRowClick(row.syncId, row)}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    style={{ border: "1px solid #ddd", cursor: "pointer" }}
                    onClick={() => handleRowClick(row.syncId, row)}
                  >
                    {trimAndTooltip(row.assigneeName || "N/A", 20)}
                  </TableCell>
                  <TableCell
                    style={{ border: "1px solid #ddd", cursor: "pointer" }}
                    onClick={() => handleRowClick(row.syncId, row)}
                  >
                    {getSyncRequestTypeName(Number(row.syncType))}
                  </TableCell>
                  <TableCell
                    style={{
                      color: getStatusColor(Number(row.status)),
                      border: "1px solid #ddd",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRowClick(row.syncId, row)}
                  >
                    {getSyncStatusTypeName(Number(row.status))}
                  </TableCell>
                  <TableCell
                    style={{ border: "1px solid #ddd", cursor: "pointer" }}
                    onClick={() => handleRowClick(row.syncId, row)}
                  >
                    {trimAndTooltip(row.message, 25)}
                  </TableCell>
                  <TableCell
                    style={{ border: "1px solid #ddd", cursor: "pointer" }}
                    onClick={() => handleRowClick(row.syncId, row)}
                  >
                    {row.startedAt ? convertToIST(row.startedAt) : "--"}
                  </TableCell>
                  <TableCell
                    style={{ border: "1px solid #ddd", cursor: "pointer" }}
                    onClick={() => handleRowClick(row.syncId, row)}
                  >
                    {row.completedAt ? convertToIST(row.completedAt) : "--"}
                  </TableCell>
                  <TableCell
                    style={{ border: "1px solid #ddd", cursor: "pointer" }}
                    onClick={() => handleRowClick(row.syncId, row)}
                  >
                    {row.projectCount}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd" }}>
                    {parseInt(row.status) !== 3 &&
                    parseInt(row.status) !== 4 &&
                    parseInt(row.status) !== 5 ? (
                      <button
                        style={{
                          backgroundColor: "#f44336",
                          color: "white",
                          border: "none",
                          padding: "10px 20px",
                          textAlign: "center",
                          textDecoration: "none",
                          display: "inline-block",
                          fontSize: "14px",
                          margin: "4px 2px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          transition: "background-color 0.3s",
                        }}
                        onClick={() => {
                          handleCompleteSync(row.syncId);
                        }}
                      >
                        Complete
                      </button>
                    ) : (
                      // <button
                      //   style={{
                      //     backgroundColor: "#4CAF50",
                      //     color: "white",
                      //     border: "none",
                      //     padding: "10px 20px",
                      //     textAlign: "center",
                      //     textDecoration: "none",
                      //     display: "inline-block",
                      //     fontSize: "14px",
                      //     margin: "4px 2px",
                      //     cursor: "not-allowed",
                      //     borderRadius: "5px",
                      //     transition: "background-color 0.3s",
                      //   }}
                      // >
                      //   Completed
                      // </button>
                      <p></p>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <button className="scroll-button" onClick={handleScrollButtonClick}>
          {scrollDirection === "down" ? <IoArrowDown /> : <IoArrowUp />}
        </button>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, filteredSyncRequestData.length]}
        component="div"
        count={filteredSyncRequestData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export default SyncRequestTable;
