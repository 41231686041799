import React from 'react';
 // Replace with your CSS file path
import './index.scss'
const ToolTipComponent = () => {
  return (
    <div>
         <div> <span className="circle darkgreen"></span> : ≤ 0%</div>
          <pre><span className="circle green"></span> : 0% - 5%</pre>
          <span className="circle yellow"></span> : 5% - 10% 
         <pre></pre> <span className="circle orange"></span> : 10% - 15% 
         <pre></pre>
          <span className="circle red"></span> : 15% 
    </div>
  );
};

export default ToolTipComponent;
