import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import DashboardHeader from "components/DashboardHeader";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  getSummaryData,
  updateManualAnnualSummary,
  updateRevenueLostByFinancialYear,
} from "common/api/ApiService";
import "./index.scss";
import {
  FaEdit as EditIcon,
  FaSave as SaveIcon,
  FaTimes as CancelIcon,
} from "react-icons/fa";

interface OpportunityItem {
  name: string;
  design: number;
  development: number;
  qa: number;
  total: number;
}

interface OpportunityRevenueLost {
  name: string;
  design: number;
  development: number;
  qa: number;
  total: number;
  items: {
    [key: string]: OpportunityItem;
  };
}

interface ResourceItem {
  name: string;
  design: number;
  development: number;
  qa: number;
  total: number;
  items?: {
    [key: string]: ResourceItem;
  };
}

interface Resources {
  name: string;
  design: number;
  development: number;
  qa: number;
  total: number;
  items: {
    [key: string]: ResourceItem;
  };
}

interface SummaryData {
  q1_lost_revenue: number;
  opportunity_revenue_lost: OpportunityRevenueLost;
  resources: Resources;
}

interface ManualDataResponse {
  oppRevenueDevSowSigned?: number;
  oppRevenueDesignSowSigned?: number;
  oppRevenueQaSowSigned?: number;
  leaveCountDev?: number;
  leaveCountDesign?: number;
  leaveCountQa?: number;
  revenueDesign?: number;
  revenueDev?: number;
  revenueRateQa?: number;
}

const formatRupees = (amount: number): string => {
  return new Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 0,
  }).format(amount);
};

const formatRupeesWithSymbol = (amount: number): string => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  }).format(amount);
};

const SummaryDashboard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState<SummaryData[]>([]);
  const [editMode, setEditMode] = useState<string | null>(null);
  const [editedValues, setEditedValues] = useState<Partial<ResourceItem>>({});
  const editableSections = [
    "On Leave",
    "SOW Signed and Work not Started",
    "Others",
  ];
  const [isSaved, setIsSaved] = useState(false);
  const [newFieldValue, setNewFieldValue] = useState<string>("0");
  const [isEditingNewField, setIsEditingNewField] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsSaved(false);
    const fetchSummaryDashboardData = async () => {
      setLoading(true);
      try {
        const resp = await getSummaryData();
        setNewFieldValue(resp[0].q1_lost_revenue.toString());
        setSummaryData(resp);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchSummaryDashboardData();
  }, [isSaved]);

  const handleEditClick = (name: string) => {
    setEditMode(name);
  };

  const handleNewFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewFieldValue(event.target.value);
  };

  const handleNewFieldEdit = () => {
    setIsEditingNewField(true);
  };

  const handleNewFieldSave = async (newFieldValue: string) => {
    const saveQ1RevenueLost = {
      q1LostRevenue: parseInt(newFieldValue),
    };
    try {
      const response = await updateManualAnnualSummary(
        "fy2425",
        saveQ1RevenueLost
      );
      setIsSaved(true);
    } catch (error) {
      console.error("Error saving data:", error);
    }
    // Logic to save the new field value
    setIsEditingNewField(false);
  };

  const handleSaveClick = async (name: string) => {
    try {
      let dataToUpdate: Partial<ManualDataResponse> = {};
      let dataToUpdateOthers: Partial<ManualDataResponse> = {};

      if (name === "On Leave") {
        dataToUpdate = {
          leaveCountDesign: editedValues?.design,
          leaveCountDev: editedValues?.development,
          leaveCountQa: editedValues?.qa,
        };
      } else if (name === "SOW Signed and Work not Started") {
        dataToUpdate = {
          oppRevenueDesignSowSigned: editedValues?.design,
          oppRevenueDevSowSigned: editedValues?.development,
          oppRevenueQaSowSigned: editedValues?.qa,
        };
      } else if (name === "Others") {
        dataToUpdateOthers = {
          revenueDesign: editedValues?.design,
          revenueDev: editedValues?.development,
          revenueRateQa: editedValues?.qa,
        };
      }

      let response;

      if (name === "On Leave" || name === "SOW Signed and Work not Started") {
        response = await updateManualAnnualSummary("fy2425", dataToUpdate);
      } else if (name === "Others") {
        response = await updateRevenueLostByFinancialYear(
          "fy2425",
          dataToUpdateOthers
        );
      }
      setIsSaved(true);
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setEditMode(null);
      setEditedValues({});
    }
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof ResourceItem
  ) => {
    const { value } = event.target;
    setEditedValues({ ...editedValues, [field]: Number(value) });
  };

  const renderTableRows = (
    items: { [key: string]: OpportunityItem | ResourceItem } | undefined,
    level: number = 0,
    isOpportunity: boolean = false
  ) => {
    if (!items) return null;

    // Determine if any sibling items have subitems
    const hasSubItems = Object.values(items).some(
      (item) => (item as ResourceItem).items
    );

    // Function to determine font weight based on level and subitems presence
    const getFontWeight = (level: number) => {
      switch (level) {
        case 0:
          return hasSubItems ? "bold" : "normal";
        case 1:
          return hasSubItems ? "700" : "normal";
        case 2:
          return hasSubItems ? "600" : "normal";
        default:
          return "normal";
      }
    };

    // Function to determine font color based on level and subitems presence
    const getFontColor = (level: number) => {
      switch (level) {
        case 0:
          return hasSubItems ? "#000000" : "black"; // Dark Black for level 0
        case 1:
          return hasSubItems ? "#333333" : "black"; // Light Black for level 1
        case 2:
          return hasSubItems ? "#666666" : "black"; // Lighter Black for level 2
        default:
          return "black";
      }
    };

    return (
      <>
        {Object.values(items).map((item) => (
          <React.Fragment key={item.name}>
            <TableRow>
              <TableCell
                style={{ paddingLeft: `${level * 25}px` }}
                className="table-cell-border"
              >
                <div className="table-cell-icons">
                  <span
                    style={{
                      fontWeight: getFontWeight(level),
                      color: getFontColor(level),
                    }}
                  >
                    {item.name}
                  </span>
                  {editableSections.includes(item.name) &&
                    (editMode === item.name ? (
                      <>
                        <div className="icons-display">
                          <Tooltip title="Save changes">
                            <IconButton
                              sx={{ color: "#4CAF50", fontSize: "1rem" }}
                              onClick={() => handleSaveClick(item.name)}
                            >
                              <SaveIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel changes">
                            <IconButton
                              sx={{ color: "#FF5722", fontSize: "1rem" }}
                              onClick={() => setEditMode(null)}
                            >
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </>
                    ) : (
                      <Tooltip title="Edit">
                        <IconButton
                          sx={{ color: "#2196F3", fontSize: "1rem" }}
                          onClick={() => handleEditClick(item.name)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ))}
                </div>
              </TableCell>
              {editMode === item.name ? (
                <>
                  <TableCell className="table-cell-border">
                    <input
                      value={editedValues.design ?? item.design}
                      placeholder={item.design.toString()}
                      onChange={(e) => handleInputChange(e, "design")}
                      className="input-line-edited"
                      style={{ padding: "0", margin: "0" }}
                    />
                  </TableCell>
                  <TableCell className="table-cell-border">
                    <input
                      value={editedValues.development ?? item.development}
                      placeholder={item.development.toString()}
                      onChange={(e) => handleInputChange(e, "development")}
                      className="input-line-edited"
                    />
                  </TableCell>
                  <TableCell className="table-cell-border">
                    <input
                      value={editedValues.qa ?? item.qa}
                      placeholder={item.qa.toString()}
                      onChange={(e) => handleInputChange(e, "qa")}
                      className="input-line-edited"
                    />
                  </TableCell>
                  <TableCell className="table-cell-border">
                    {isOpportunity
                      ? formatRupeesWithSymbol(item.total)
                      : formatRupees(item.total)}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell
                    style={{
                      fontWeight: getFontWeight(level),
                      color: getFontColor(level),
                      textAlign: "right",
                    }}
                    className="table-cell-border"
                  >
                    {isOpportunity
                      ? formatRupeesWithSymbol(item.design)
                      : formatRupees(item.design)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: getFontWeight(level),
                      color: getFontColor(level),
                      textAlign: "right",
                    }}
                    className="table-cell-border"
                  >
                    {isOpportunity
                      ? formatRupeesWithSymbol(item.development)
                      : formatRupees(item.development)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: getFontWeight(level),
                      color: getFontColor(level),
                      textAlign: "right",
                    }}
                    className="table-cell-border"
                  >
                    {isOpportunity
                      ? formatRupeesWithSymbol(item.qa)
                      : formatRupees(item.qa)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: getFontWeight(level),
                      color: getFontColor(level),
                      textAlign: "right",
                    }}
                    className="table-cell-border"
                  >
                    {isOpportunity
                      ? formatRupeesWithSymbol(item.total)
                      : formatRupees(item.total)}
                  </TableCell>
                </>
              )}
            </TableRow>
            {(item as ResourceItem).items &&
              renderTableRows((item as ResourceItem).items, level + 1)}
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderCombinedTable = (data: SummaryData[]) => (
    <TableContainer component={Paper} className="summary-dashboard">
      <Table stickyHeader>
        <TableHead>
          <TableRow
            style={{
              fontWeight: "bold",
              backgroundColor: "#f1f1f1",
            }}
            className="table-cell-border"
          >
            <TableCell
              style={{
                fontWeight: "bold",
                backgroundColor: "#f1f1f1",
              }}
              className="table-cell-border"
            >
              <div className="table-cell-icons">
                Q1 Revenue Lost
                {isEditingNewField ? (
                  <div className="icons-display">
                    <Tooltip title="Save changes">
                      <IconButton
                        sx={{ color: "#4CAF50", fontSize: "1rem" }}
                        onClick={() => handleNewFieldSave(newFieldValue)}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel changes">
                      <IconButton
                        sx={{ color: "#FF5722", fontSize: "1rem" }}
                        onClick={() => setIsEditingNewField(false)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <Tooltip title="Edit">
                    <IconButton
                      sx={{ color: "#2196F3", fontSize: "1rem" }}
                      onClick={() => handleNewFieldEdit()}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                backgroundColor: "#f1f1f1",
                textAlign: "right",
              }}
              colSpan={4}
              className="table-cell-border"
            >
              {isEditingNewField ? (
                <input
                  value={newFieldValue}
                  onChange={handleNewFieldChange}
                  className="input-line-edited-new"
                  style={{ padding: "0", margin: "0" }}
                />
              ) : (
                formatRupeesWithSymbol(parseInt(newFieldValue))
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                fontWeight: "bold",
                backgroundColor: "#f7f5f5",
              }}
              className="table-cell-border"
            >
              Financial Year 2024-Present
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                backgroundColor: "#f7f5f5",
              }}
              className="table-cell-border"
            >
              Design
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                backgroundColor: "#f7f5f5",
              }}
              className="table-cell-border"
            >
              Development
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                backgroundColor: "#f7f5f5",
              }}
              className="table-cell-border"
            >
              QA
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                backgroundColor: "#f7f5f5",
              }}
              className="table-cell-border"
            >
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((summaryItem) => (
            <React.Fragment key={summaryItem.opportunity_revenue_lost.name}>
              {/* Revenue Lost Rows */}
              <TableRow
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#f1f1f1",
                  textAlign: "right",
                }}
                className="table-cell-border"
              >
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                  }}
                  className="table-cell-border"
                >
                  {summaryItem.opportunity_revenue_lost.name}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                    textAlign: "right",
                  }}
                  className="table-cell-border"
                >
                  {formatRupeesWithSymbol(
                    summaryItem.opportunity_revenue_lost.design
                  )}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                    textAlign: "right",
                  }}
                  className="table-cell-border"
                >
                  {formatRupeesWithSymbol(
                    summaryItem.opportunity_revenue_lost.development
                  )}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                    textAlign: "right",
                  }}
                  className="table-cell-border"
                >
                  {formatRupeesWithSymbol(
                    summaryItem.opportunity_revenue_lost.qa
                  )}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                    textAlign: "right",
                  }}
                  className="table-cell-border"
                >
                  {formatRupeesWithSymbol(
                    summaryItem.opportunity_revenue_lost.total
                  )}
                </TableCell>
              </TableRow>
              {renderTableRows(
                summaryItem.opportunity_revenue_lost.items,
                1,
                true
              )}

              {/* Resources Rows */}
              <TableRow
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#f1f1f1",
                  textAlign: "right",
                }}
                className="table-cell-border"
              >
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                  }}
                  className="table-cell-border"
                >
                  {summaryItem.resources.name}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                    textAlign: "right",
                  }}
                  className="table-cell-border"
                >
                  {formatRupees(summaryItem.resources.design)}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                    textAlign: "right",
                  }}
                  className="table-cell-border"
                >
                  {formatRupees(summaryItem.resources.development)}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                    textAlign: "right",
                  }}
                  className="table-cell-border"
                >
                  {formatRupees(summaryItem.resources.qa)}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#f1f1f1",
                    textAlign: "right",
                  }}
                  className="table-cell-border"
                >
                  {formatRupees(summaryItem.resources.total)}
                </TableCell>
              </TableRow>
              {renderTableRows(summaryItem.resources.items, 1, false)}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <DashboardHeader />
      <div
        className="summary-header-container"
        style={{ marginTop: "5px", marginBottom: "2px" }}
      >
        <span
          onClick={() => navigate("/")}
          className="summary-back-icon-dashboard"
        >
          <IoArrowBack size={24} />
        </span>
        <h1>Opportunity Dashboard</h1>
      </div>

      <div>{renderCombinedTable(summaryData)}</div>
    </>
  );
};

export default SummaryDashboard;
