import React, { useEffect, useState } from 'react';
import { postBenchTimeData, AssigneeLoggedHours } from 'common/api/ApiService';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { capitalize } from "utils/Capitalize";
import { GrPowerReset } from "react-icons/gr"; 

const Dept = (departmentId: string | null) => {
  if (!departmentId) return "--"; 

  switch (departmentId) {
    case "1":
      return "Design";
    case "2":
      return "Development";
    case "3":
      return "QA";
    default:
      return "--"; 
  }
};

const EmploymentType = (employmentType: string | null) => {
  return employmentType ? capitalize(employmentType) : "--"; 
};

interface Payload {
  projectId: number[];
  fromDate: string;
  toDate: string;
}

interface BenchTableProps {
  payload: Payload;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const BenchTimeTable: React.FC<BenchTableProps> = ({
  payload,
  loading,
  setLoading,
}) => {
  const [assigneeLoggedHours, setAssigneeLoggedHours] = useState<AssigneeLoggedHours[]>([]);
  const [sortBy, setSortBy] = useState<{
    field: keyof AssigneeLoggedHours;
    order: "asc" | "desc";
  }>({ field: "totalLoggedTime", order: "asc" });
  const [activeSort, setActiveSort] = useState(false);

  useEffect(() => {
    const fetchAssigneeLoggedHours = async () => {
      try {
        setLoading(true); 
        const data = await postBenchTimeData(payload);
        setAssigneeLoggedHours(data);
        // console.log(assigneeLoggedHours)
      } catch (error) {
        console.error("Error fetching bench time:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssigneeLoggedHours();
  }, [payload, setLoading]);


  const secondsToHours = (seconds: number) => {
    return (seconds).toFixed(2); 
  };


  const handleSort = (field: keyof AssigneeLoggedHours) => {
    setActiveSort(true);
    if (sortBy.field === field) {
      setSortBy({ ...sortBy, order: sortBy.order === "asc" ? "desc" : "asc" });
    } else {
      setSortBy({ field, order: "asc" });
    }
  };


  const handleResetSort = async () => {
    setSortBy({ field: "totalLoggedTime", order: "asc" });
    setActiveSort(false);
    setLoading(true); 
    try {
      const data = await postBenchTimeData(payload);
      setAssigneeLoggedHours(data);
    } catch (error) {
      console.error("Error fetching bench time:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {!loading && assigneeLoggedHours.length === 0 ? (
        <Typography variant="h6" align="center" className="empty-records">
          No records found
        </Typography>
      ) : (
        <TableContainer component={Paper} className="project-table-container">
          <Table sx={{ minWidth: 750 }}>
            <TableHead sx={{ backgroundColor: "#f7f5f5" }}>
              <TableRow>
                <TableCell className="table-header"
                  sx={{
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                    paddingRight: "16px",
                  }}
                >
                  Department
                </TableCell>
                <TableCell className="table-header"
                  sx={{
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                    paddingRight: "16px",
                  }}
                >
                  Employment Type
                </TableCell>
                <TableCell className="table-header"
                  sx={{
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                    paddingRight: "16px",
                  }}
                >
                  Assignee Name
                </TableCell>
                <TableCell className="table-header" sx={{ fontWeight: "bold", paddingRight: "16px" }}>
                  <TableSortLabel
                    active={sortBy.field === "totalLoggedTime"}
                    direction={
                      sortBy.field === "totalLoggedTime" ? sortBy.order : "asc"
                    }
                    onClick={() => handleSort("totalLoggedTime")}
                  >
                    Total Logged Time (Hours)
                  </TableSortLabel>
                  {activeSort && (
                    <GrPowerReset
                      onClick={handleResetSort}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assigneeLoggedHours
                .sort((a, b) => {
                  const order = sortBy.order === "asc" ? 1 : -1;
                  const deptA = Dept(a.departmentId);
                  const deptB = Dept(b.departmentId);
                  return order * deptA.localeCompare(deptB); 
                })
                .map((assignee, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #ddd",
                        paddingRight: "16px",
                        width: "500px",
                      }}
                    >
                      {Dept(assignee.departmentId)}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #ddd",
                        paddingRight: "16px",
                      }}
                    >
                      {EmploymentType(assignee.employmentType)}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #ddd",
                        paddingRight: "16px",
                      }}
                    >
                      {capitalize(assignee.firstName)}
                    </TableCell>
                    <TableCell sx={{ paddingRight: "16px", textAlign: "right" }}>
                      {secondsToHours(assignee.totalLoggedTime)}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell
                  colSpan={2}
                  sx={{
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                    paddingRight: "16px",
                  }}
                >
                  Total hours for all departments
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    paddingRight: "16px",
                    textAlign: "right",
                  }}
                >
                  {secondsToHours(
                    assigneeLoggedHours.reduce(
                      (total, assignee) => total + assignee.totalLoggedTime,
                      0
                    )
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default BenchTimeTable;
