import React from 'react';
import './index.scss'; // Replace with your CSS file path

const BillingStatus = () => {
  return (
    <div>
     
      <div className="billing-status">
        <span className="circle darkgreen"></span> : On Budget
        <pre></pre>
        <span className="circle red"></span> : Under Budget
        <pre></pre>
        <span className="circle yellow"></span> : Over Budget
      </div>
    </div>
  );
};

export default BillingStatus;
