import React from 'react';
 // Replace with your CSS file path
import './index.scss'
const Progress = () => {
  return (
    <div>
      
          <span className="circle darkgreen"></span> : Closed
         <pre><span className="circle gray"></span> : Not Started </pre>
          <span className="circle yellow"></span> : In Progress
       <pre></pre>
          <span className="circle red"></span> : On Hold
    </div>
  );
};

export default Progress;
