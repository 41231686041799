import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
} from "@mui/material";
import { Typography } from "@material-ui/core";
import { postTimesheetData } from "common/api/ApiService";
import moment from "moment";
import "./index.scss";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize";

interface ProjectData {
  name: string;
  issues: {
    name: string;
    description: string | null;
    issueId: number;
    assignee: {
      firstName: string | null;
    };
    timeLogs: {
      loggedTime: number;
      comments: string | null;
      jiraTimeLoggedAt: string;
      jiraTimeLoggedByName?: string;
    }[];
  }[];
}

interface WorkItem {
  assignee: string;
  time: number;
  date: string;
}

interface AssigneeData {
  monthYear: string;
  assignees: WorkItem[];
}

interface ProjectSummary {
  project: string;
  issues: AssigneeData[];
  totalHours: number;
  workingDays: number;
}

interface Payload {
  projectId: number[];
  fromDate: string;
  toDate: string;
}

interface ProjectWiseTableProps {
  payload: Payload;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProjectData: ProjectData[];
  setSelectedProjectData: React.Dispatch<React.SetStateAction<ProjectData[]>>;
}

const ProjectWiseTable: React.FC<ProjectWiseTableProps> = ({
  payload,
  loading,
  setLoading,
  selectedProjectData,
  setSelectedProjectData,
}) => {
  const [filteredData, setFilteredData] = useState<ProjectSummary[]>([]);
  const [projectData, setProjectData] = useState<ProjectData[]>([]);
  const navigate = useNavigate();
  // Fetch data when component mounts or when payload changes
  useEffect(() => {
    // console.log("selectedProjectData", selectedProjectData);
    localStorage.setItem(
      "selectedProjectData",
      JSON.stringify(selectedProjectData)
    );
  }, [selectedProjectData]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const projects: ProjectData[] = await postTimesheetData(payload);
        // console.log(JSON.stringify(projects));
        setProjectData(projects);
        const data = filterProjectData(projects);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching projects data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [payload]);

  // Function to get the range of dates for a given month
  const getMonthDateRange = (
    monthYear: string,
    fromDate: string,
    toDate: string
  ) => {
    const [month, year] = monthYear.split(" ");
    const startDate = moment(`${month} 1, ${year}`, "MMM D, YYYY").startOf(
      "month"
    );
    const endDate = moment(startDate).endOf("month");

    const rangeStart = moment.max(startDate, moment(fromDate));
    const rangeEnd = moment.min(endDate, moment(toDate));

    return `${rangeStart.format("MMM D, YYYY")} - ${rangeEnd.format(
      "MMM D, YYYY"
    )}`;
  };

  // Function to process and filter project data into a summarized format
  const filterProjectData = (projects: ProjectData[]): ProjectSummary[] => {
    const projectSummaries: ProjectSummary[] = [];

    projects.forEach((project) => {
      const projectSummary: ProjectSummary = {
        project: project.name,
        issues: [],
        totalHours: 0,
        workingDays: 0,
      };

      const projectAssigneeLogsMap: Map<
        string,
        Map<string, number>
      > = new Map();

      project.issues.forEach((issue) => {
        issue.timeLogs.forEach((timeLog) => {
          const assigneeName = timeLog.jiraTimeLoggedByName;
          if (!assigneeName) {
            return;
          }
          const createdAt = new Date(timeLog.jiraTimeLoggedAt);
          const monthYear = `${createdAt.toLocaleString("default", {
            month: "short",
          })} ${createdAt.getFullYear()}`;

          let monthMap = projectAssigneeLogsMap.get(monthYear);
          if (!monthMap) {
            monthMap = new Map();
            projectAssigneeLogsMap.set(monthYear, monthMap);
          }

          const totalLoggedTime = monthMap.get(assigneeName) || 0;
          monthMap.set(assigneeName, totalLoggedTime + timeLog.loggedTime);
        });
      });

      projectAssigneeLogsMap.forEach((assigneeMap, monthYear) => {
        const assignees: WorkItem[] = [];
        let totalMonthTime = 0;

        assigneeMap.forEach((totalLoggedTime, assigneeName) => {
          const totalTimeInHours = totalLoggedTime / 3600;
          const formattedTotalTime = Number(totalTimeInHours.toFixed(2));

          assignees.push({
            assignee: assigneeName,
            time: formattedTotalTime,
            date: "",
          });

          totalMonthTime += formattedTotalTime;
        });

        if (assignees.length > 0) {
          assignees.push({
            assignee: "Subtotal",
            time: totalMonthTime,
            date: "",
          });

          projectSummary.issues.push({
            monthYear: monthYear,
            assignees: assignees,
          });
        }
      });

      if (projectSummary.issues.length > 0) {
        let subtotalTime = 0;
        projectSummary.issues.forEach((issue) => {
          issue.assignees.forEach((assignee) => {
            if (assignee.assignee !== "Subtotal") {
              subtotalTime += assignee.time;
            }
          });
        });

        projectSummary.issues.push({
          monthYear: "Total",
          assignees: [
            {
              assignee: "",
              time: Number(subtotalTime.toFixed(2)),
              date: "",
            },
          ],
        });

        projectSummary.totalHours = subtotalTime;
      }

      projectSummary.issues.sort((a, b) => {
        const [aMonth, aYear] = a.monthYear.split(" ");
        const [bMonth, bYear] = b.monthYear.split(" ");
        return (
          new Date(`${aMonth} 1, ${aYear}`).getTime() -
          new Date(`${bMonth} 1, ${bYear}`).getTime()
        );
      });

      projectSummary.issues.forEach((issue) => {
        issue.assignees.sort((a, b) => {
          if (a.assignee === "Subtotal" || b.assignee === "Subtotal") {
            return 0;
          }
          return a.assignee.localeCompare(b.assignee);
        });
      });

      projectSummaries.push(projectSummary);
    });
    // console.log(projectSummaries);
    return projectSummaries.sort((a, b) => a.project.localeCompare(b.project));
  };

  const handleAssigneeClick = (
    assignee: string,
    monthYear: string,
    project: ProjectSummary,
    projectClicked: boolean = false
  ) => {
    // console.log(assignee, monthYear, project, projectClicked);
    // console.log(JSON.stringify(projectData,null,2));
    let filterProjectData = projectData.filter(
      (p) => p.name === project.project
    );
    // console.log(JSON.stringify(filterProjectData, null, 2), "filtered");
    if (!projectClicked) {
      // Filter time logs for each issue based on the assignee
      filterProjectData = filterProjectData.map((p) => ({
        ...p,
        issues: p.issues.map((issue) => ({
          ...issue,
          timeLogs: issue.timeLogs.filter(
            (log) => log.jiraTimeLoggedByName === assignee
          ),
        })),
      }));
    }

    setSelectedProjectData(filterProjectData);
    navigateToSubTimesheetsNewTab();
    // navigate("/subtimesheets");
  };

  const navigateToSubTimesheetsNewTab = () => {
    window.open(`/subtimesheets`, "_blank");
  };

  // JSX for the modal
  // const modalContent = (
  //   <div className="modal-container">
  //     <div className="modal-header">
  //       <Typography variant="h6" align="center">
  //         {selectedAssignee}'s - {selectedProject}
  //       </Typography>
  //       <IoCloseCircleOutline
  //         className="close-icon-modal"
  //         onClick={() => setIsModalOpen(false)}
  //       />
  //     </div>
  //     <Table className="modal-table">
  //       <TableHead>
  //         <TableRow>
  //           <TableCell className="table-cell">Project</TableCell>
  //           <TableCell className="table-cell">Issue Name</TableCell>
  //           <TableCell className="table-cell">Date</TableCell>
  //           <TableCell className="table-cell">Time Logged (hrs)</TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {selectedData.map((project, projectIndex) =>
  //           project.issues.map((issue: any, issueIndex: number) =>
  //             issue.time.map((log: any, logIndex: number) => (
  //               <TableRow
  //                 key={`${project.name}-${issue.issueName}-${log.date}`}
  //               >
  //                 {logIndex === 0 && issueIndex === 0 && (
  //                   <TableCell
  //                     rowSpan={project.issues.reduce(
  //                       (acc: any, currIssue: any) =>
  //                         acc + currIssue.time.length,
  //                       0
  //                     )}
  //                     className="table-cell"
  //                   >
  //                     {project.name}
  //                   </TableCell>
  //                 )}
  //                 {logIndex === 0 && (
  //                   <TableCell
  //                     rowSpan={issue.time.length}
  //                     className="table-cell"
  //                   >
  //                     {issue.issueName}
  //                   </TableCell>
  //                 )}
  //                 <TableCell className="table-cell">{log.date}</TableCell>
  //                 <TableCell className="table-cell-time">
  //                   {log.timeLogged}
  //                 </TableCell>
  //               </TableRow>
  //             ))
  //           )
  //         )}
  //         {/* Total row */}
  //         <TableRow>
  //           <TableCell colSpan={3} className="total-label">
  //             Total
  //           </TableCell>
  //           <TableCell className="total-hours">
  //             {/* Calculate total hours for all issues */}
  //             {selectedData.reduce(
  //               (acc, project) =>
  //                 acc +
  //                 project.issues.reduce(
  //                   (issueAcc: any, issue: any) =>
  //                     issueAcc +
  //                     issue.time.reduce(
  //                       (logAcc: any, log: any) => logAcc + log.timeLogged,
  //                       0
  //                     ),
  //                   0
  //                 ),
  //               0
  //             )}{" "}
  //             hrs
  //           </TableCell>
  //         </TableRow>
  //       </TableBody>
  //     </Table>
  //   </div>
  // );

  // To display project wise data
  return (
    <TableContainer component={Paper} className="project-table-container">
      {!loading && filteredData.length === 0 ? (
        <Typography variant="h6" align="center" className="empty-records">
          No records found
        </Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold" }}
              >
                Project
              </TableCell>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold" }}
              >
                Month/Year
              </TableCell>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold" }}
              >
                Assignee
              </TableCell>
              <TableCell
                className="table-header"
                style={{ fontWeight: "bold" }}
              >
                Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map(
              (project: ProjectSummary, projectIndex: number) => (
                <React.Fragment key={`project_${projectIndex}`}>
                  {project.issues.map(
                    (issue: AssigneeData, issueIndex: number) => (
                      <React.Fragment key={`issue_${issueIndex}`}>
                        {issue.assignees.map(
                          (workItem: WorkItem, workIndex: number) => (
                            <TableRow key={`workItem_${workIndex}`}>
                              {/*grouping rows based on project name */}
                              {workIndex === 0 && issueIndex === 0 && (
                                <TableCell
                                  rowSpan={project.issues.reduce(
                                    (acc, currAssignee) =>
                                      acc + currAssignee.assignees.length,
                                    0
                                  )}
                                  className="table-cell"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleAssigneeClick(
                                      workItem.assignee,
                                      issue.monthYear,
                                      project,
                                      true
                                    )
                                  }
                                >
                                  {capitalize(project.project)}
                                </TableCell>
                              )}
                              {/*grouping rows based on month year*/}
                              {workIndex === 0 &&
                                issue.monthYear === "Total" && (
                                  <TableCell
                                    rowSpan={issue.assignees.length}
                                    colSpan={2}
                                    className="table-cell"
                                  >
                                    <b>{issue.monthYear}</b>
                                  </TableCell>
                                )}
                              {workIndex === 0 &&
                                issue.monthYear !== "Total" && (
                                  <TableCell
                                    rowSpan={issue.assignees.length}
                                    className="table-cell"
                                  >
                                    {issue.monthYear}
                                  </TableCell>
                                )}
                              {issue.monthYear !== "Total" && (
                                <>
                                  {workItem.assignee === "Subtotal" && (
                                    <>
                                      <TableCell className="table-cell">
                                        <b>{capitalize(workItem.assignee)}</b>
                                      </TableCell>
                                      <TableCell
                                        className="table-cell"
                                        style={{
                                          textAlign: "right",
                                          paddingRight: "10px",
                                        }}
                                      >
                                        <b>{workItem.time.toFixed(2)} hrs</b>
                                      </TableCell>
                                    </>
                                  )}
                                  {workItem.assignee !== "Subtotal" && (
                                    <>
                                      <TableCell
                                        className="table-cell"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleAssigneeClick(
                                            workItem.assignee,
                                            issue.monthYear,
                                            project
                                          )
                                        }
                                      >
                                        {capitalize(workItem.assignee)}
                                      </TableCell>
                                      <TableCell
                                        className="table-cell"
                                        style={{
                                          textAlign: "right",
                                          paddingRight: "10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleAssigneeClick(
                                            workItem.assignee,
                                            issue.monthYear,
                                            project
                                          )
                                        }
                                      >
                                        {workItem.time.toFixed(2)}
                                      </TableCell>
                                    </>
                                  )}
                                </>
                              )}
                              {issue.monthYear === "Total" && (
                                <TableCell
                                  className="table-cell"
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <b>{workItem.time.toFixed(2)} hrs</b>
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        )}
                      </React.Fragment>
                    )
                  )}
                </React.Fragment>
              )
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default ProjectWiseTable;
