import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableSortLabel,
} from "@mui/material";
import { timesheetDeafaultersData } from "../../common/api/ApiService";
import "./Index.scss";
import { capitalize } from "utils/Capitalize";

interface ProjectData {
  firstName: string;
  lastName: string | null;
  departmentId: number;
  hasJiraAccess: number;
  assigneeId: number;
  issues: {
    name: string;
    description: string | null;
    issueId: number;
    projectId: number;
    projectName: string;
    timeLogs: {
      issueId: number;
      loggedTime: number;
      comments: string | null;
      loggedAt: string;
    }[];
  }[];
}

interface WorkItem {
  name: string;
  time: number;
  date: string;
  projectName: string;
}

interface AssigneeData {
  name: string;
  workItems: WorkItem[];
  overallHours: number;
}

interface ProjectSummary {
  assignees: AssigneeData[];
  totalHours: number;
  workingDays: number;
}

interface Payload {
  projectId: number[];
  fromDate: string;
  toDate: string;
}

interface ProjectTableProps {
  payload: Payload;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const PersonTable: React.FC<ProjectTableProps> = ({
  payload,
  loading,
  setLoading,
}) => {
  const [filteredData, setFilteredData] = useState<ProjectSummary[]>([]);
  const [sortedData, setSortedData] = useState<ProjectSummary[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [sortColumn, setSortColumn] = useState<string>("Time Logged (hrs)");
  // const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortState, setSortState] = useState<{
    column: string | null;
    direction: "asc" | "desc" | undefined;
    count: number;
  }>({ column: null, direction: undefined, count: 0 });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const projects: ProjectData[] = await timesheetDeafaultersData(payload);
        const data = filterProjectData(projects);
        // console.log(JSON.stringify(data))
        setFilteredData(data);
        setSortedData(data);
        setLoading(false);
      } catch (error) {
        setError("Error fetching projects data");
        console.error("Error fetching projects data:", error);
      }
    };

    fetchData();
  }, [payload]);

  const handleSort = (column: string) => {
    let { count, column: sortColumn, direction } = sortState;
    count++;
    // console.log(sortState, count, column, direction);
    const isAsc = sortColumn === column && direction === "asc";
    direction = isAsc ? "desc" : "asc";
    // console.log(direction);
    if (count === 3) {
      setSortState({ column: null, direction: undefined, count: 0 });
      return setSortedData(filteredData);
    }
    setSortState({ column, direction, count });

    const sortedData = [...filteredData].sort((a, b) => {
      // Extracting the correct value for sorting based on the column
      let valueA: any, valueB: any;

      switch (column) {
        case "Time Logged (hrs)":
          valueA = a.assignees[0].overallHours;
          valueB = b.assignees[0].overallHours;
          break;
        default:
          // Default to sorting by assignee name
          valueA = a.assignees[0].name.toLowerCase();
          valueB = b.assignees[0].name.toLowerCase();
      }

      // Compare values based on sort direction
      if (typeof valueA === "string" && typeof valueB === "string") {
        return isAsc
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return isAsc ? valueB - valueA : valueA - valueB;
      }
    });

    setSortedData(sortedData);
  };

  const filterProjectData = (projects: ProjectData[]): ProjectSummary[] => {
    const summary: ProjectSummary[] = [];

    projects.forEach((project) => {
      let assigneeData = summary.find((summaryItem) =>
        summaryItem.assignees.some(
          (assignee) =>
            assignee.name === `${project.firstName} ${project.lastName ?? ""}`
        )
      );

      if (!assigneeData) {
        assigneeData = {
          assignees: [
            {
              name: `${project.firstName} ${project.lastName ?? ""}`,
              workItems: [], // Initialize workItems as an empty array
              overallHours: 0,
            },
          ],
          totalHours: 0,
          workingDays: 0,
        };
        summary.push(assigneeData);
      }
      project.issues.forEach((issue) => {
        let assigneeData = summary.find((summaryItem) =>
          summaryItem.assignees.some(
            (assignee) =>
              assignee.name === `${project.firstName} ${project.lastName ?? ""}`
          )
        );

        if (!assigneeData) {
          assigneeData = {
            assignees: [
              {
                name: `${project.firstName} ${project.lastName ?? ""}`,
                workItems: [], // Initialize workItems as an empty array
                overallHours: 0,
              },
            ],
            totalHours: 0,
            workingDays: 0,
          };
          summary.push(assigneeData);
        }
        issue.timeLogs.forEach((log) => {
          // Find or create assigneeData
          let assigneeData = summary.find((summaryItem) =>
            summaryItem.assignees.some(
              (assignee) =>
                assignee.name ===
                `${project.firstName} ${project.lastName ?? ""}`
            )
          );

          if (!assigneeData) {
            assigneeData = {
              assignees: [
                {
                  name: `${project.firstName} ${project.lastName ?? ""}`,
                  workItems: [], // Initialize workItems as an empty array
                  overallHours: 0,
                },
              ],
              totalHours: 0,
              workingDays: 0,
            };
            summary.push(assigneeData);
          }

          // Find the assignee within assigneeData
          const assignee = assigneeData.assignees.find(
            (assignee) =>
              assignee.name === `${project.firstName} ${project.lastName ?? ""}`
          );

          if (assignee) {
            // Create a new workItem object
            const workItem: WorkItem = {
              name: issue.name,
              time: log.loggedTime,
              date: new Date(log.loggedAt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              }),
              projectName: issue.projectName,
            };

            // Push workItem and update overallHours safely
            assignee.workItems.push(workItem);
            assignee.overallHours += log.loggedTime;
            assignee.workItems.sort((a, b) => {
              const projectNameA = a.projectName.toLowerCase();
              const projectNameB = b.projectName.toLowerCase();
              if (projectNameA < projectNameB) {
                return -1;
              }
              if (projectNameA > projectNameB) {
                return 1;
              }
              return 0;
            });
          } else {
            console.error(
              "Assignee not found in assigneeData:",
              `${project.firstName} ${project.lastName ?? ""}`
            );
          }
        });
      });
    });

    //sort by assignee name
    // Sort assignees within each ProjectSummary by name
    summary.forEach((summaryItem) => {
      summaryItem.assignees.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    });

    // Sort ProjectSummary array by the name of the first assignee
    summary.sort((a, b) => {
      const nameA = a.assignees[0].name.toLowerCase();
      const nameB = b.assignees[0].name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return summary;
  };

  if (loading) {
    return (
      <Typography variant="h6" align="center">
        Loading...
      </Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" align="center" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <TableContainer component={Paper} className="project-table-container">
      {sortedData.length === 0 ? (
        <Typography variant="h6" align="center" className="empty-records">
          No records found
        </Typography>
      ) : (
        <Table className="project-table">
          <TableHead>
            <TableRow>
              <TableCell className="table-header">Assignee</TableCell>
              <TableCell className="table-header">Project</TableCell>
              <TableCell className="table-header">WorkItem</TableCell>
              <TableCell className="table-header">
                <TableSortLabel
                  active={sortState.column === "Time Logged (hrs)"}
                  direction={
                    sortState.column === "Time Logged (hrs)"
                      ? sortState.direction
                      : "asc"
                  }
                  onClick={() => handleSort("Time Logged (hrs)")}
                >
                  Time Logged (hrs)
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((summary, summaryIndex) => (
              <React.Fragment key={summaryIndex}>
                {summary.assignees.map((assignee, assigneeIndex) => (
                  <React.Fragment key={`${summaryIndex}_${assigneeIndex}`}>
                    {assignee.workItems.length === 0 && (
                      <>
                        <TableCell className="table-cell">
                          {capitalize(assignee.name)}
                        </TableCell>
                        <TableCell className="table-cell">--</TableCell>
                        <TableCell className="table-cell">--</TableCell>
                        <TableCell className="table-cell" align="right">
                          0.00
                        </TableCell>
                      </>
                    )}
                    {assignee.workItems.map((workItem, workIndex) => (
                      <TableRow
                        key={`${summaryIndex}_${assigneeIndex}_${workIndex}`}
                      >
                        {workIndex === 0 && (
                          <TableCell
                            rowSpan={assignee.workItems.length}
                            className="table-cell"
                          >
                            {capitalize(assignee.name)}
                          </TableCell>
                        )}
                        <TableCell className="table-cell">
                          {capitalize(workItem.projectName)}
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{ maxWidth: "250px", overflow: "h" }}
                        >
                          {workItem.name}
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{ textAlign: "right", paddingRight: "10px" }}
                        >
                          {workItem.time % 1 === 0
                            ? workItem.time.toFixed(2)
                            : workItem.time.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        className="table-cell"
                        style={{ fontWeight: "bold" }}
                      >
                        {capitalize(assignee.name)}'s subtotal:
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        className="table-cell"
                        style={{
                          fontWeight: "bold",
                          textAlign: "right",
                          paddingRight: "10px",
                        }}
                      >
                        {assignee.overallHours % 1 === 0
                          ? assignee.overallHours.toFixed(2)
                          : assignee.overallHours.toFixed(2)}{" "}
                        hrs
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default PersonTable;
