import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import "./index.scss";
interface SelectOption {
  value: string;
  label: string;
}

interface TimeSheetTypeComponentProps {
  selectedTimeSheetType: string;
  setSelectedTimeSheetType: React.Dispatch<React.SetStateAction<string>>;
}

//Dropdown for selectinf type of timesheet
const TimeSheetTypeComponent: React.FC<TimeSheetTypeComponentProps> = ({
  selectedTimeSheetType,
  setSelectedTimeSheetType,
}) => {
  const options: SelectOption[] = [
    { value: "Timesheet", label: "Timesheet" },
    { value: "ProjectWiseTimesheet", label: "Project Wise Timesheet" },
    { value: "PersonWiseTimesheet", label: "Person Wise Timesheet" },
    { value: "TimeSheetDefaulters", label: "Person Wise Logged Hours" },
    { value: "BenchTime", label: "Billable Vs Non-Billable Hours" },
  ];
  // Set default selected time sheet type to "Timesheet"
  React.useEffect(() => {
    setSelectedTimeSheetType("Timesheet");
  }, [setSelectedTimeSheetType]);
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedTimeSheetType(event.target.value);
  };

  return (
    <FormControl
      sx={{ display: "flex", flexDirection: "column", paddingLeft: "14px" }}
      className="sheet-filter-select-label"
    >
      <InputLabel id="select-label">Report Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        label="Report Type"
        value={selectedTimeSheetType}
        onChange={handleChange}
        variant="standard"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        sx={{ paddingLeft: "14px" }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeSheetTypeComponent;
